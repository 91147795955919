<template>
  <div
    class="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800"
  >
    <div
      v-show="data.showMap"
      ref="directionMap"
      class=" w-full h-56 bg-red-400"
    />

    <div class="flex justify-between px-2 py-2 bg-green-300">
      <h1 class="mx-3 py-1 w-full text-lg text-center font-semibold text-gray-900">
        Shop Direction
      </h1>
      <button
        class="px-3 py-1 rounded text-lg text-center font-semibold focus:outline-none"
        :class="[data.showMap ? 'bg-orange-600 text-white' : 'bg-white text-gray-900']"
        @click="showDirectionMap"
      >
        Direction
      </button>
    </div>

    <div class="px-6 py-4">
      <div class="border border-gray-200 rounded-md p-2 ">
        <h1 class="text-lg mt-2 uppercase font-semibold text-gray-800 dark:text-white">
          {{ orderData.restaurentData.name }}
        </h1>

        <p class="py-1 text-gray-700 dark:text-gray-400">
          {{ orderData.restaurentData.address }}
        </p>
      </div>
      <div class="flex py-2 items-center text-gray-700 dark:text-gray-200">
        <unicon
          name="clipboard-alt"
          fill="royalblue"
        />
        <h1 class="px-2 text-sm">
          Order: {{ orderData.orderId }}
        </h1>
      </div>
      <div class="flex items-center text-gray-700 dark:text-gray-200">
        <unicon
          name="user-circle"
          fill="royalblue"
        />
        <h1 class="px-2 text-sm">
          Customer: {{ orderData.buyer.name }}
        </h1>
      </div>
    </div>
    <div class="flex items-center justify-between px-3 py-3">
      <button
        class="rounded-full w-full px-4 py-2 font-medium text-white uppercase transition-colors duration-200
        transform bg-green-600 hover:bg-green-500
        focus:outline-none"
        @click="$emit('ReachedLocation')"
      >
        Reached Pickup Location
      </button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-undef */
import { useGeolocation } from '@vueuse/core'
import { useGetDistance } from '@/composibles/useGetDistance'
import { onMounted, ref, toRef } from 'vue'
import { useGmap } from '@/composibles/useGmap'
const { loader } = useGmap()

const emit = defineEmits(['ReachedLocation'])

const { coords, locatedAt, error } = useGeolocation()
console.log('coords, locatedAt, error ', coords.value, locatedAt.value, error.value)

let map = null
const props = defineProps({
  order: {
    type: Array,
    default: () => []
  }
})
const orderData = toRef(props, 'order')
const directionMap = ref(null)
const directionsService = ref(null)
const directionsRenderer = ref(null)
// const marker = ref(null)
const { lat: slat, lng: slng } = orderData.value.restaurentData
const { lat: dlat, lng: dlng } = orderData.value.shippingCoords

console.log('new Order props, ', orderData.value)

const data = ref({
  shop: { lat: slat, lng: slng }, // Restaurant
  rider: { lat: dlat, lng: dlng }, // Rider
  drop: { lat: dlat, lng: dlng }, // Drop Shipping
  trip: {
    tripDistance: '',
    pickup: '',
    drop: '',
    duration: ''
  },
  showMap: false
})

const showDirectionMap = async () => {
  data.value.showMap = !data.value.showMap
  if (data.value.showMap) {
    if (!map) {
      await directions()
    }
  }
}

const directions = async () => {
  await loader.load()
  const shopWay = []
  const route = {
    origin: data.value.rider,
    travelMode: google.maps.TravelMode.DRIVING
  }
  if (orderData.value.restaurentData.length > 1) {
    orderData.value.restaurentData.map(res => {
      shopWay.push({ location: { lat: res.lat, lng: res.lng } })
      return true
    })
    const { lat: lslat, lng: lslng } = orderData.value.restaurentData[orderData.value.restaurentData.length - 1]
    route.destination = { lat: lslat, lng: lslng }
    route.waypoints = shopWay
    route.optimizeWaypoints = true
  } else {
    route.destination = data.value.shop
  }

  map = new google.maps.Map(directionMap.value, {
    center: data.value.rider,
    disableDefaultUI: true
  })
  directionsService.value = new google.maps.DirectionsService()
  directionsRenderer.value = new google.maps.DirectionsRenderer()
  directionsRenderer.value.setMap(map)
  directionsService.value.route(route)
    .then((response) => {
      console.log('response Directions', response)
      directionsRenderer.value.setDirections(response)
    })
    .catch((e) => {
      console.log('Directions request failed due to ' + e)
    })
}

// onMounted(async () => {
//   await orderDistance()
// })

</script>
