import repository from '../../api/repository.js'
import adminRepository from '@/api/adminRepository'
export const namespaced = true
export const getters = {

}
export const state = {
  categories: {},
  productsCategories: null,
  getPopularCategories: {}
}
export const mutations = {
  SET_CATEGORY (state, data) {
    state.categories = data
  },
  SET_PRODUCT_CATEGORY (state, data) {
    state.productsCategories = data
  },
  SET_POPULAR_CATEGORY (state, data) {
    state.getPopularCategories = data
  }

}
export const actions = {
  async getShopCategory ({ commit }, param) {
    const { data } = await repository.getShopCategory(param)
    commit('SET_CATEGORY', data)
  },
  // async getShopProductsCategory ({ commit, rootState }, param) {
  //   // console.log('from store..', param)
  //   const { data } = await repository.getShopProductsCategory(param.id, param.shop_id)
  //   rootState.shop.shopproducts = data
  // },
  async storeCategory ({ commit }, category) {
    const { data } = await adminRepository.storeCategory(category)
    commit('SET_PRODUCT_CATEGORY', data)
  },
  async updateCategories ({ commit }, categoryData) {
    const { data } = await adminRepository.updateCategories(categoryData)
    commit('SET_PRODUCT_CATEGORY', data)
  },
  async getCategories ({ commit }, page) {
    const { data } = await adminRepository.getCategories(page)
    commit('SET_PRODUCT_CATEGORY', data)
  },
  async getPopularCategories ({ commit }) {
    const { data } = await adminRepository.getPopularCategories()
    commit('SET_POPULAR_CATEGORY', data)
  },
  async updatePopularCategories ({ commit }, category) {
    const { data } = await adminRepository.updatePopularCategories(category)
    console.log(data)
    // commit('SET_POPULAR_CATEGORY', data)
  }
}
