<template>
  <p
    v-if="$store.state.Notifications.count"
    class="absolute text-white rounded-full -top-2 -right-3 bg-green-500 w-6 h-6"
    @click="getNotifications"
  >
    {{ $store.state.Notifications.count }}
  </p>
</template>

<script setup>
import { collection, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { firebase } from '@/firebase'
import { reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import Notification from '@/api/notification'

const store = useStore()

const data = reactive({
  orders: [],
  unsubscribe: null,
  user: JSON.parse(sessionStorage.getItem('user')),
  status: null
})

const watchOrders = () => {
  const q = query(collection(firebase, 'orders'),
    where('userId', '==', data.user[0].id),
    orderBy('createdAt', 'asc')
  )
  data.unsubscribe = onSnapshot(q, (querySnapshot) => {
    data.orders = []
    querySnapshot.forEach((doc) => {
      if (doc.data().status !== 1 && doc.data().status !== '1') {
        data.status = doc.data().status
      }
      data.orders.push({ id: doc.id, ...doc.data() })
    })
    getNotifications()
    console.log('getNotifications() watchOrders', data.user[0].id)
  })
}

const getNotifications = async () => {
  const notifyData = await store.dispatch('Notifications/getNotifications', data.user[0].id)
  console.log('response.data', notifyData)
}

onMounted(() => {
  if (data.user) {
    watchOrders()
    console.log('landing layout onMounted user if user ')
  }
})
</script>

<style scoped>

</style>
