import repository from '@/api/repository'

export const namespaced = true
export const state = {
  // carts: sessionStorage.carts ? JSON.parse(sessionStorage.getItem('carts')) : [],
  carts: localStorage.cart !== undefined ? JSON.parse(localStorage.getItem('cart')) : [],
  shipping: 0,
  distances: [],
  // restaurantsCarts: sessionStorage.restaurantsCarts ? JSON.parse(sessionStorage.getItem('restaurantsCarts')) : [],
  restaurantsCarts: [],
  distanceValue: 0,
  cartId: 0
}
export const getters = {
  cart_data: (state) => {
    return state.carts
  },
  cartItem: (state) => state.carts.length > 0,
  distances: (state) => {
    return state.distances
  },
  restaurantsCarts: (state) => {
    return state.restaurantsCarts
  }
}
export const mutations = {
  SET_SHIPPING_COST (state, data) {
    state.shipping += parseInt(data)
  }
}
export const actions = {
  async storeCart ({ commit, state }, cart) {
    const { data } = await repository.saveCarts(cart)
    // state.carts = state.carts.concat(data)
    // state.carts = data
  },
  async removeCartItem ({ commit }, cartItem) {
    const { data } = await repository.removeCartItem(cartItem)
    console.log(data)
  },
  async incrementCartItem ({ commit }, cartItem) {
    const { data } = await repository.incrementCartItem(cartItem)
    console.log(data)
  },
  async decrementCartItem ({ commit }, cartItem) {
    const { data } = await repository.decrementCartItem(cartItem)
    console.log(data)
  },
  async getCartItems ({ commit, state, rootState }, payload) {
    const { data } = await repository.getCartItems(payload)
    state.carts = data
    // const cartold = [...state.carts.concat(data.cart)]
    // if (data.message === 'success') {
    //   state.carts = state.carts.concat(data.cart)
    //   if (rootState.shop.restaurants.length > 0) {
    //     const filteredResults = data.shops.filter(item =>
    //       rootState.shop.restaurants.every(val => item.id !== val.id)
    //     )
    //     rootState.shop.restaurants = [...rootState.shop.restaurants, ...filteredResults]
    //   } else {
    //     rootState.shop.restaurants = [...rootState.shop.restaurants, ...data.shops]
    //   }
    // }
    return data
    // console.log('cart data....', data)
    // console.log(['from get cart item..', data.carts])
    // data.carts.forEach(c => {
    //   c.product.q = c.quantity
    //   state.carts.push(c.product)
    // })
  },
  async getDistance ({ commit, state }, param) {
    const { data } = await repository.getDistance(param)
    state.distanceValue = Math.round(parseInt(data.rows[0].elements[0].distance.text))
    // console.log('distance from php........1', data)
    // await repository.getDistance(param)
    // eslint-disable-next-line array-callback-return
    // state.restaurantsCarts[0].distance = Math.round(parseInt(data.rows[0].elements[0].distance.text))
    // console.log(data, parseInt(data.rows[0].elements[0].distance.text))
    // data.rows[0].elements.map((d, i) => {
    //   state.restaurantsCarts[i].distance = parseInt(data.rows[0].elements[i].distance.text)
    //   return true
    // })
    // data.rows[0].elements[0].distance.value
  },
  async getDistanceMultiple ({ commit, state }, param) {
    const { data } = await repository.getDistance(param)
    // state.distanceValue = Math.round(parseInt(data.rows[0].elements[0].distance.text))
    // await repository.getDistance(param)
    // eslint-disable-next-line array-callback-return
    // state.restaurantsCarts[0].distance = Math.round(parseInt(data.rows[0].elements[0].distance.text))
    console.log('distance from php multiple...', data)
    data.rows[0].elements.map((d, i) => {
      state.restaurantsCarts[i].deliveryDistance = parseInt(data.rows[0].elements[i].distance.text)
      return true
    })
    // data.rows[0].elements[0].distance.value
  },
  async cartCalculate ({ commit, state }, param) {
    state.restaurantsCarts = []
    const { data } = await repository.cartCalculate(param)
    state.restaurantsCarts = data
    // console.log(data)
  },
  totalShippingCost ({ commit }, data) {
    commit('SET_SHIPPING_COST', data)
  }
}
// let res = this.$store.state.shop.restaurants.filter(r => {
//     if(shop_id.includes(r.id)){
//         let c = this.cart_data.filter(d => d.shop_id == r.id)
//         r['carts'] = c
//         return true
//     }
// })
