import repository from '@/api/repository'
import adminrepository from '@/api/adminRepository'
export const namespaced = true
export const state = {
  reviews: {},
  deliveryreviews: {},
  avg_rating: 0,
  count_review: 0,
  productReviewUser: [],
  tobeViewed: null,
  sellerProductReview: [],
  productReviews: [],
  replyReview: null
}
export const getters = {
  allreviews: (state) => {
    return state.reviews
  },
  alldeliveryreviews: (state) => {
    return state.deliveryreviews
  },
  average_rating: (state) => {
    return state.avg_rating
  },
  count_review: (state) => {
    return state.count_review
  }

}
export const mutations = {
  SET_REVIEWS (state, data) {
    state.reviews = data[0]
    state.avg_rating = data[1]
    state.count_review = data[2]
  },
  SET_DELIVERY_REVIEWS (state, data) {
    state.deliveryreviews = data
  },
  SET_USER_REVIEWS (state, data) {
    state.productReviewUser = data
  },
  SET_TOBE_REVIEWED (state, data) {
    state.tobeViewed = data
  },
  SET_SELLER_PRODUCT_REVIEW (state, data) {
    state.sellerProductReview = data
    state.productReviews = data
  },
  SET_PRODUCT_REVIEW_BY_PRODUCT (state, data) {
    state.productReviews = data
  }
}

export const actions = {
  async saveReview ({ commit }, review) {
    const { data } = await repository.saveReview(review)
    commit('SET_REVIEWS', data)
  },
  async getProductReview ({ commit }, product) {
    const { data } = await repository.getProductReview(product)
    commit('SET_REVIEWS', data)
  },
  async saveDeliveryReview ({ commit }, review) {
    const { data } = await repository.saveDeliveryReview(review)
    commit('SET_DELIVERY_REVIEWS', data)
  },
  async getDeliveryReview ({ commit }, product) {
    const { data } = await repository.getDeliveryReview(product)
    commit('SET_DELIVERY_REVIEWS', data)
  },
  async userProductReviews ({ commit }, user) {
    const { data } = await repository.userProductReviews(user)
    commit('SET_USER_REVIEWS', data)
  },
  async userTobeReviewed ({ commit }, user) {
    const { data } = await repository.userTobeReviewed(user)
    commit('SET_TOBE_REVIEWED', data)
  },
  async sellerProductReviews ({ commit }, seller) {
    const { data } = await adminrepository.sellerProductReviews(seller)
    commit('SET_SELLER_PRODUCT_REVIEW', data)
  },
  async shopProductReviews ({ commit }, shop) {
    const { data } = await adminrepository.shopProductReviews(shop)
    commit('SET_SELLER_PRODUCT_REVIEW', data)
  },
  async productReviewsbyproduct ({ commit }, product) {
    const { data } = await adminrepository.productReviews(product)
    commit('SET_PRODUCT_REVIEW_BY_PRODUCT', data)
  },
  async replyReview ({ commit, state }, reply) {
    const { data } = await adminrepository.replyReview(reply)
    state.replyReview = data
  },
  async removeReplyReview ({ commit }, id) {
    const { data } = await adminrepository.removeReplyReview(id)
    console.log(data)
    // state.replyReview = data
  }
}
