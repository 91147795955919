import user from '@/router/middleware/user.js'
import LandingLayout from '@/Layouts/LandingLayout'

export default [
  {
    path: '/buyer/trackOrder',
    name: 'trackOrder',
    // component: () => import(/* webpackChunkName: "trackOrder" */ '@/views/Buyer/Order/trackOrder'),
    component: () => import(/* webpackChunkName: "trackOrder" */ '@/views/Admin/Buyer/Account/pages/orderTrracking'),
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Track Order'
    }
  },
  {
    path: '/buyer/orders',
    name: 'Order',
    // component: () => import(/* webpackChunkName: "order" */ '@/views/Buyer/Order/orders'),
    component: () => import(/* webpackChunkName: "order" */ '@/views/Admin/Buyer/Account/pages/orders'),
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Order'
    }
  },
  {
    path: '/buyer/orderDetails',
    name: 'OrderDetails',
    // component: () => import(/* webpackChunkName: "order" */ '@/views/Buyer/Order/orders'),D:\VueProjects\dmsfrontend\src\components\AdminComponent\Buyer\orderDetails.vue
    component: () => import(/* webpackChunkName: "orderdetails" */ '@/components/AdminComponent/Buyer/orderDetails'),
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'OrderDetails'
    }
  },
  {
    path: '/buyer/manageAccount',
    name: 'manageAccount',
    component: () => import(/* webpackChunkName: "manageAccount" */ '@/views/Admin/Buyer/Account/manageAccount'),
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Manage Account'
    }
  },
  {
    path: '/buyer/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "buyerNotification" */ '@/views/Admin/Buyer/Order/Notifications'),
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Order Notification'
    }
  }
]
