import user from '@/router/middleware/user.js'
import LandingLayout from '@/Layouts/LandingLayout'
import AdminLayout from '@/Layouts/AdminLayout'
import AuthLayout from '@/Layouts/AuthLayout.vue'

const ShopProducts = () => import(/* webpackChunkName: "ShopProducts" */ '@/views/Site/Shop/shopProducts')

export default [
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Site/Profile/Profile'),
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Profile'
    }
  },
  {
    path: '/addresslist',
    component: () => import(/* webpackChunkName: "AddressList" */ '@/views/Site/Profile/AddressList'),
    name: 'Address Book',
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Address Book'
    }
  },
  {
    path: '/shopProducts/:id/:slug',
    // component: () => import(/* webpackChunkName: "ShopProduct" */ '@/views/Site/Shop/shopProducts'),
    component: ShopProducts,
    name: 'ShopProducts',
    props: true,
    meta: {
      layout: LandingLayout,
      comp: ShopProducts,
      title: 'Products'
    }
  },
  {
    path: '/product-details/:id',
    component: () => import(/* webpackChunkName: "shopProduct" */ '@/views/Site/Shop/productDetails'),
    name: 'ProductDetails',
    props: true,
    meta: {
      layout: LandingLayout,
      title: 'Product Details'
    }
  },
  {
    path: '/:role/cartList',
    name: 'CartList',
    beforeEnter: user,
    component: () => import(/* webpackChunkName: "Cart" */'@/views/Site/Cart/Cart'),
    meta: {
      layout: LandingLayout,
      title: 'Cart'
    }
  },
  {
    path: '/:role/checkout',
    component: () => import(/* webpackChunkName: "Checkout" */'@/views/Site/CheckOut/checkout'),
    name: 'Checkout',
    beforeEnter: user,
    meta: {
      layout: LandingLayout,
      title: 'Checkout'
    }
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "AdminHome" */'@/views/Admin/SuperAdmin/AdminHome'),
    name: 'AdminHome',
    // beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Admin'
    }
  },
  {
    path: '/:role/dashboard',
    component: () => import(/* webpackChunkName: "AdminHome" */'@/views/Admin/Dashboard/dashboard'),
    name: 'Dashboard',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Dashboard'
    }
  },
  // {
  //   path: '/admin/dashboard',
  //   component: () => import(/* webpackChunkName: "AdminHome" */'@/views/Admin/dashboard'),
  //   name: 'Dashboard',
  //   beforeEnter: user,
  //   meta: {
  //     layout: AdminLayout,
  //     title: 'Dashboard'
  //   }
  // },
  {
    path: '/users',
    component: () => import(/* webpackChunkName: "AdminHome" */'@/views/Admin/SuperAdmin/AdminHome'),
    name: 'Users',
    // beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Users'
    }
  },
  {
    path: '/admin/sellers',
    component: () => import(/* webpackChunkName: "Sellers" */'@/views/Admin/sellers/index'),
    name: 'Sellers',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Sellers'
    }
  },
  {
    path: '/admin/sellers/active',
    component: () => import(/* webpackChunkName: "ActiveSellers" */'@/views/Admin/sellers/Active'),
    name: 'ActiveSellers',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Active Sellers'
    }
  },
  {
    path: '/admin/sellers/locked',
    component: () => import(/* webpackChunkName: "LockedSellers" */'@/views/Admin/sellers/Locked'),
    name: 'LoackedSellers',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Locked Sellers'
    }
  },
  {
    path: '/admin/riders/active',
    component: () => import(/* webpackChunkName: "ActiveRiders" */'@/views/Admin/riders/Active'),
    name: 'ActiveRiders',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Active Riders'
    }
  },
  {
    path: '/admin/riders/locked',
    component: () => import(/* webpackChunkName: "LockedRiders" */'@/views/Admin/riders/Locked'),
    name: 'LoackedRiders',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Locked Riders'
    }
  },
  {
    path: '/admin/buyers',
    component: () => import(/* webpackChunkName: "Buyers" */'@/views/Admin/SuperAdmin/Buyer'),
    name: 'Buyers',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Buyers'
    }
  },
  {
    path: '/admin/roles',
    component: () => import(/* webpackChunkName: "Roles" */'@/views/Admin/SuperAdmin/Roles'),
    name: 'Roles',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Roles'
    }
  },
  {
    path: '/admin/orders',
    component: () => import(/* webpackChunkName: "Orders" */'@/views/Admin/Order/Orders'),
    name: 'Orders',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Orders'
    }
  },
  {
    path: '/admin/completed-orders',
    component: () => import(/* webpackChunkName: "Orders" */'@/views/Admin/Order/CompletedOrders'),
    name: 'CompletedOrders',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Completed Orders'
    }
  },
  {
    path: '/admin/assignRole',
    component: () => import(/* webpackChunkName: "RoleUser" */'@/views/Admin/SuperAdmin/RoleUser'),
    name: 'Assign Role',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Assign Role'
    }
  },
  {
    path: '/seller/signup',
    name: 'Seller SignUp',
    component: () => import(/* webpackChunkName: "ShopProducts" */ '@/views/Site/Authentication/signUpSeller'),
    meta: {
      layout: AuthLayout,
      title: 'Seller Sign Up'
    }
  },
  {
    path: '/rider/signup',
    name: 'Rider SignUp',
    component: () => import(/* webpackChunkName: "signUpRider" */'@/views/Site/Authentication/signUpRider'),
    meta: {
      layout: AuthLayout,
      title: 'Rider Sign Up'
    }
  },
  {
    path: '/seller/dashboard',
    component: () => import(/* webpackChunkName: "SellerDashboard" */'@/views/Admin/Seller/dashboard'),
    name: 'SellerDashboard',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Seller Dashboard'
    }
  },
  {
    path: '/buyer/dashboard',
    component: () => import(/* webpackChunkName: "BuyerDashboard" */'@/views/Admin/Buyer/dashboard'),
    name: 'BuyerDashboard',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Buyer Dashboard'
    }
  },
  {
    path: '/:role/menu-manage',
    component: () => import(/* webpackChunkName: "MenuManagment" */'@/views/Admin/SuperAdmin/menuManagment'),
    name: 'MenuManagment',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Menu Managment'
    }
  },
  {
    path: '/helpline/dashboard',
    component: () => import(/* webpackChunkName: "HelplineDashboard" */'@/views/Admin/Helpline/dashboard'),
    name: 'HelplineDashboard',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Helpline Dashboard'
    }
  },
  {
    path: '/rider/dashboard',
    component: () => import(/* webpackChunkName: "RiderDashboard" */'@/views/Admin/riders/dashboard'),
    name: 'RiderDashboard',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Rider Dashboard'
    }
  },
  {
    path: '/:role/payment',
    component: () => import(/* webpackChunkName: "RiderDashboard" */'@/views/Admin/Payment/payment'),
    name: 'Payment',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Payment'
    }
  },
  {
    path: '/admin/policy',
    component: () => import(/* webpackChunkName: "Policy" */'@/views/Admin/Policy/policy'),
    name: 'Policy',
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Policy'
    }
  }
]
