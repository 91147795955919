import adminrepository from '@/api/adminRepository'
export const namespaced = true
export const state = {
  pendingOrder: false,
  buyers: []
}
export const getters = {

}
export const mutations = {
//   SET_SELLERS (state, sellers) {
//     state.sellers = sellers
//   },
//   SET_ACTIVE_SELLERS (state, activeSeller) {
//     state.activeSeller = activeSeller
//   },
//   SET_LOCK_SELLERS (state, lockSeller) {
//     state.lockSeller = lockSeller
//   },
//   SET_BUYERS (state, buyers) {
//     state.buyers = buyers
//   }
}
export const actions = {
  async getAdminDashboardCount ({ commit }) {
    const { data } = await adminrepository.getAdminDashboardCount()
    return data
  },
  async searchCompletedOrdersByDate ({ commit }, payload) {
    const { data } = await adminrepository.searchCompletedOrdersByDate(payload)
    return data
  },
  async searchPendingOrdersByDate ({ commit }, payload) {
    const { data } = await adminrepository.searchPendingOrdersByDate(payload)
    return data
  },
  async searchCompletedOrdersByNo ({ commit }, payload) {
    const { data } = await adminrepository.searchCompletedOrdersByNo(payload)
    return data
  },
  async searchPendingOrdersByNo ({ commit }, payload) {
    const { data } = await adminrepository.searchPendingOrdersByNo(payload)
    return data
  },
  async getFavouriteProduct ({ commit }, payload) {
    const { data } = await adminrepository.getFavouriteProduct(payload)
    return data
  }
  // async searchBuyers ({ commit }, keyword) {
  //   const { data } = await adminrepository.searchBuyers(keyword)
  //   commit('SET_BUYERS', data)
  // }
//   async getSellers ({ commit }, page) {
//     const { data } = await adminrepository.getSellers(page)
//     commit('SET_SELLERS', data)
//   },
//   async getActiveSellers ({ commit }, page) {
//     const { data } = await adminrepository.activeSellers(page)
//     commit('SET_ACTIVE_SELLERS', data)
//   },
//   async searchActiveSellers ({ commit }, keyword) {
//     const { data } = await adminrepository.searchActiveSellers(keyword)
//     commit('SET_ACTIVE_SELLERS', data)
//   },
//   async getLockSellers ({ commit }, page) {
//     const { data } = await adminrepository.lockSellers(page)
//     commit('SET_LOCK_SELLERS', data)
//   },
//   async searchLockSellers ({ commit }, keyword) {
//     const { data } = await adminrepository.searchLockSellers(keyword)
//     commit('SET_LOCK_SELLERS', data)
//   },
//   async statusChange ({ commit }, user) {
//     await adminrepository.statusChange(user)
//   }
}
