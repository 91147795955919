<template>
  <div class="bg-indigo-900 relative overflow-hidden h-screen">
    <img
      src="@/assets/404.svg"
      class="absolute h-full w-full object-cover"
    >
    <div class="inset-0 bg-black opacity-25 absolute" />
    <div class="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 xl:py-40">
      <div class="w-full font-mono flex flex-col items-center relative z-10">
        <h1 class="font-extrabold text-4xl text-center text-white leading-tight mt-4">
          You&#x27;re alone here
        </h1>
        <button
          @click="hasHistory()
            ? $router.go(-1)
            : $router.push('/')"
        >
          <button class="px-2 py-2 w-36 mt-12 font-light transition ease-in duration-200 hover:bg-yellow-400 border-2 text-lg border-gray-700 bg-yellow-300 focus:outline-none rounded-md">
            Go Back
          </button>
        </button>
        <p class="font-extrabold text-8xl my-44 text-white animate-bounce">
          404
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    document.title = this.$route.name
  },
  methods: {
    hasHistory () { return window.history.length > 2 }
  }
}
</script>
