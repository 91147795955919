import notification from '@/api/notification'
// import adminRepository from '@/api/adminRepository'
export const namespaced = true
export const state = {
  count: 0,
  notifications: []
}

export const mutations = {
}
export const actions = {
  async deliveryStatus ({ commit }, param) {
    const { data } = await notification.deliveryStatus(param)
    return data
  },
  async getNotifications ({ commit, state }, param) {
    const { data } = await notification.GetUserNotifications(param)
    console.log('getNotifications', data)
    state.count = data.count
    state.notifications = data.notifications
    return data
  },
  async markAsRead ({ commit }, param) {
    const { data } = await notification.markAsRead(param)
    return data
  }
}
