import user from '@/router/middleware/user.js'
import AdminLayout from '@/Layouts/AdminLayout'

export default [
  {
    path: '/rider/profile',
    name: 'riderProfile',
    component: () => import(/* webpackChunkName: "createShop" */ '@/views/Admin/riders/Profile/profile'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Profile'
    }
  },
  {
    path: '/rider/completed-rides',
    name: 'Completedride',
    component: () => import(/* webpackChunkName: "createShop" */ '@/views/Admin/riders/CompletedRide/completed.vue'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Completed Rides'
    }
  }
]
