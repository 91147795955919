import Unicon from 'vue-unicons'
import {
  uniShoppingCart, uniUserCircle, uniPlus, uniMinus, uniDashboard, uniShop, uniCommentAltDots, uniShoppingBag, uniNotes,
  uniListOl, uniPlusSquare, uniSignout, uniUserSquare, uniMoon, uniStore, uniUserCheck, uniUserTimes, uniUserMd, uniCarSideview,
  uniMoneyBill, uniUserArrows, uniUsersAlt, uniBookReader, uniSubject, uniShieldPlus, uniListUl, uniUser, uniArrowsHAlt, uniAngleDoubleLeft,
  uniTimes, uniElipsisDoubleVAlt, uniAngleUp, uniAngleDown, uniLayerGroup, uniAwardAlt, uniAsterisk, uniCheck, uniLayers, uniLock, uniUnlock,
  uniTrash, uniBars, uniClipboardAlt, uniClock, uniRestaurant, uniPhone, uniBell
} from 'vue-unicons/dist/icons'

Unicon.add([uniShoppingCart, uniUserCircle, uniPlus, uniMinus, uniDashboard, uniShop, uniCommentAltDots, uniShoppingBag, uniNotes,
  uniListOl, uniPlusSquare, uniSignout, uniUserSquare, uniMoon, uniStore, uniUserCheck, uniUserTimes, uniUserMd, uniCarSideview, uniMoneyBill,
  uniUserArrows, uniUsersAlt, uniBookReader, uniSubject, uniShieldPlus, uniListUl, uniUser, uniArrowsHAlt, uniAngleDoubleLeft, uniTimes,
  uniElipsisDoubleVAlt, uniAngleUp, uniAngleDown, uniLayerGroup, uniAwardAlt, uniAsterisk, uniCheck, uniLayers, uniLock, uniUnlock, uniTrash,
  uniBars, uniClipboardAlt, uniClock, uniRestaurant, uniPhone, uniBell])

export function useUnicons () {
  return { Unicon }
}
