import { onMounted } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'

const loader = new Loader({
  apiKey: 'AIzaSyCp9GnA7lBGeTWeYVsj3JdR5_1xS6b3uvc',
  libraries: ['places']
})

export function useGmap () {
  onMounted(async () => {
    await loader.load()
  })
  return { loader }
}
