<template>
  <div class="flex flex-col min-h-screen">
    <component :is="$route.meta.layout || 'div'">
      <transition
        name="rv"
        mode="out-in"
      >
        <router-view />
      </transition>
    </component>
  </div>
</template>
<script>
// import VueMeta from "vue-meta";
import layout from '@/Layouts/LandingLayout'
import { mapGetters } from 'vuex'
export default {
  components: { layout },
  data () {
    return {
      connectionStatus: '',
      backtoTop: false
      // buyRestaurentCount: 0
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'authenticated'
    })
  },
  mounted () {
    // console.log(this.$route.meta.layout)
    // this.isOnline()
    // this.getDistanceData()
    // this.getDistance({ lat:23.8380339,lng:90.3754975 },{ lat:23.8223486,lng:90.3654204 })

    // console.log(this.connectionStatus)
  },
  methods: {
    isOnline: function () {
      navigator.onLine ? this.connectionStatus = 'Connected to internet.' : this.connectionStatus = 'Unable to connect to internet.'
    },
    getLocation () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const { Latalngs } = this.$store.state.location
          const { latitude, longitude } = position.coords
          Latalngs.device.lat = latitude
          Latalngs.device.lng = longitude
          Latalngs.destination.lat = latitude
          Latalngs.destination.lng = longitude
          // this.showModal = true
          // this.getCurrentLocation(latitude, longitude)
        })
      } else {
        this.location = 'Geolocation is not supported by this browser.'
      }
    }
  }
}
</script>
<style>
.rv-enter-from {
  opacity: 0;
  transform: translateY(10px);
}
.rv-enter-active {
  transition: all 0.3s ease-out;
}
.rv-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
.rv-leave-active {
  transition: all 0.3s ease-in;
}
</style>
