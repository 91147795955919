import store from '../../store'

export default (to, from, next) => {
  // console.log(['tofrom....', to, from, store.state.newworkError])
  if (store.getters.authenticated) {
    next({ name: '/' })
  } else {
    next()
  }
}
