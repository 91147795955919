import repository from '@/api/repository'

export const namespaced = true
export const state = {

}
export const getters = {

}
export const mutations = {

}
export const actions = {
  async riderRegistration ({ commit, rootState }, rider) {
    const { data } = await repository.riderRegistration(rider)
    // console.log('riderRegistration comit', data)
    rootState.userRegister.userRegisterData = data[0]
  }
}
