import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { darkModeKey } from '@/config.js'
import './css/main.css'
import '@ocrv/vue-tailwind-pagination/styles'
import VueSweetalert2 from 'vue-sweetalert2'
import { messaging, auth } from '@/firebase'
import { useUnicons } from '@/composibles/useUnicons'
import { onMessage } from 'firebase/messaging'
const { Unicon } = useUnicons()

onMessage(messaging, (payload) => {
  console.log('Message received onMessage. ', payload)
})

/* Fetch sample data */
store.dispatch('policy/getPolicies')
// store.dispatch('fetch', 'history')
if (!store.getters.authenticated) {
  localStorage.removeItem('isOnline')
}
if (store.getters.authenticated && store.state.user[0].role === 'Buyer') {
  const { lat, lng } = store.state.location.Latalngs.destination
  const destinations = `${lat},${lng}`
  // store.dispatch('cart/getCartItems', { userId: store.state.user[0].id })
}

/* Dark mode */
const localStorageDarkModeValue = localStorage.getItem(darkModeKey)

if ((localStorageDarkModeValue === null && window.matchMedia('(prefers-color-scheme: dark)').matches) || localStorageDarkModeValue === '1') {
  store.dispatch('darkMode')
}

/* Default title tag */
const defaultDocumentTitle = 'Darco Food & Grocery'

/* Collapse mobile aside menu on route change */
router.beforeEach(to => {
  store.dispatch('asideMobileToggle', false)
  store.dispatch('asideLgToggle', false)
})

router.afterEach(to => {
  /* Set document title from route meta */
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }

  /* Full screen mode */
  store.dispatch('fullScreenToggle', !!to.meta.fullScreen)
})

let app = null
auth.onAuthStateChanged(() => {
  // console.log('before app ')
  if (!app) {
    app = createApp(App)
    app.config.globalProperties.$messaging = messaging
    app.use(store).use(router)
    app.use(VueSweetalert2)
    app.use(Unicon)
    app.mount('#app')
  }
})
