import api from './api'
export default {
  deliveryStatus (param) {
    console.log('deliveryStatus', param)
    return api.post('/api/notifications', param)
  },
  GetUserNotifications (user) {
    return api.get(`/api/GetUserNotifications/${user}`)
  },
  markAsRead (user) {
    return api.get(`/api/markAsRead/${user}`)
  }
}
