import store from '../../store'

export default (to, from, next) => {
  if (store.getters.authenticated) {
    const role = store.state.user[0].role.toLowerCase()
    /* eslint-disable no-useless-escape */
    const re = /^\/[^\/]*\//g
    const str = to.fullPath
    const subst = `/${role}/`
    const result = str.replace(re, subst)
    const fields = str.split('/')
    if (role === fields[1]) {
      return next()
    } else {
      return next({ path: result })
    }
    // next()
  } else {
    next({ name: 'Login', query: { redirect: to.fullPath } })
  }
}
