<template>
  <div class="flex items-center justify-center lg:min-h-screen bg-gray-100">
    <div class="px-8 text-left bg-white shadow-lg">
      <div class="flex justify-center">
        <router-link to="/">
          <img
            src="@/assets/dtl.png"
            alt=""
            class="w-20 h-20 text-blue-600"
            width="100px"
          >
        </router-link>
      </div>
      <h3 class="text-2xl font-bold text-center">
        OTP to countinue
      </h3>
      <h6 class="text-xl text-center">
        Enter 6 digit code we sent to {{ $store.state.newuser[0].mobile }}
      </h6>
      <form @submit.prevent="userValidation">
        <div class="mt-4">
          <div>
            <!--            <label-->
            <!--              class="block"-->
            <!--              for="otp"-->
            <!--            >OTP</label>-->
            <input
              v-model="state.otp"
              type="text"
              placeholder="OTP"
              class="
                w-full
                px-4
                py-2
                mt-2
                border
                rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600
              "
            >
            <span
              v-if="error"
              class="text-xs tracking-wide text-red-600"
            >
              {{ error }}</span>
            <span
              v-if="v$.otp.$error"
              class="text-xs tracking-wide text-red-600"
            >
              {{ v$.otp.$errors[0].$message }}</span>
          </div>
          <div class="flex items-baseline justify-between">
            <button
              class="
                px-6
                py-2
                mt-4
                text-white
                bg-blue-800
                rounded-lg
                hover:bg-blue-900
                w-full
              "
            >
              Confirm
            </button>
            <!-- <a href="#" class="text-sm text-blue-600 hover:underline">Forgot password?</a> -->
          </div>
        </div>
      </form>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          OTP send again
          <button
            class="text-sm text-blue-600 hover:underline"
            @click.prevent="reSend"
          >
            Resend
          </button>
        </p>
      </div>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          <router-link
            to="/"
            class="px-6 py-2 text-sm text-blue-600 hover:underline"
          >
            Go to Home
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
export default {
  setup () {
    const state = reactive({
      otp: null
    })
    const rules = computed(() => {
      return {
        otp: { required }
      }
    })
    const v$ = useValidate(rules, state)
    return { state, v$ }
  },
  data () {
    return {
      // otp: null,
      error: null
    }
  },
  mounted () {
    // console.log(["Message", this.$store.state.registration.newuser.success]);
    document.title = this.$route.name
  },
  methods: {
    async userValidation () {
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error) {
        this.error = null
        try {
          await this.$store.dispatch('NewUserValidation', {
            id: this.$store.state.newuser[0].id,
            otp: this.state.otp
          })
          // print("user state", this.$store.state.user);
          if (this.$store.state.user[0].verified === 1) {
            // await this.$router.push({ name: 'Home1' })
            await this.$router.push(this.$route.query.redirect || '/')
          } else {
            this.error = this.$store.state.user.msg
          }
        } catch (error) {
          this.error = error
        } finally {
          // this.loading = false;
        }
      } else {
        console.log('required')
      }
    },
    async reSend () {
      this.error = null
      try {
        await this.$store.dispatch('reSendotp', {
          id: this.$store.state.newuser[0].id
        })
      } catch (error) {
        this.error = error
      } finally {
        // this.loading = false;
      }
    }
  }
}
</script>
