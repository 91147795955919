import { createStore } from 'vuex'
import repository from '../api/repository'
import adminrepository from '../api/adminRepository'
import * as location from './module/location.js'
import * as Useraddress from './module/userAddress'
import * as shop from './module/shop'
import * as cart from './module/cart'
import * as category from './module/category'
import * as review from './module/review'
import * as menu from './module/menu'
import * as role from './module/role'
import * as seller from './module/sellerRegistration'
import * as sellerData from './module/seller'
import * as rider from './module/riderRegistration'
import * as riderData from './module/rider'
import * as buyer from './module/buyer'
import * as order from './module/order'
import * as policy from './module/policy'
import * as product from './module/product'
import * as userRegister from './module/userRegister'
import * as Notifications from './module/notifications'
import * as admin from './module/admin'

// import axios from 'axios'
import { darkModeKey } from '@/config.js'

export default createStore({
  namespaced: true,
  state: {
    // Site
    user: sessionStorage.user
      ? JSON.parse(sessionStorage.getItem('user'))
      : null,
    error: null,
    newuser: null,
    validate: null,
    verifiedUser: null,
    newworkError: false,

    // Admin
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    /* fullScreen - fullscreen form layout (e.g. login page) */
    isFullScreen: false,

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,

    /* Dark mode */
    darkMode: false,

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    // buyers: [],
    history: []
  },
  getters: {
    user: (state) => state.user,
    authenticated: (state) => state.user !== null,
    isBuyer: (state) => state.user ? state.user[0].role === 'Buyer' : false
  },
  mutations: {
    // Site
    SET_USER (state, user) {
      state.user = user
    },
    SET_ERROR (state, error) {
      state.error = error
    },
    SET_NEWUSER (state, newUser) {
      state.newuser = newUser
    },
    SET_NEWUSER_VALIDATION (state, newUservalidate) {
      state.validate = newUservalidate
    },
    SET_RESET_USER (state, verified) {
      state.verifiedUser = verified
    },

    // Admin
    // SET_BUYERS (state, buyers) {
    //   state.buyers = buyers
    // },
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
    }
  },
  actions: {
    // Site
    async login ({ commit }, user) {
      const session = await repository.createSession()
      // console.log('session.............', session)
      const { data } = await repository.login(user)
      if (data.msg === 'verified') {
        commit('SET_USER', data)
        sessionStorage.user = JSON.stringify(data)
        commit('SET_ERROR', null)
        commit('SET_NEWUSER', null)
      } else if (data.msg === 'notVerified') {
        commit('SET_USER', null)
        // commit("SET_ERROR", "You are not verified User!");
        commit('SET_NEWUSER', data)
        // sessionStorage.removeItem("user");
      } else {
        commit('SET_USER', null)
        commit('SET_ERROR', data.msg)
        commit('SET_NEWUSER', null)
      }
    },
    async SaveNewUser ({ commit, rootState }, newUser) {
      const { data } = await repository.registration(newUser)
      commit('SET_NEWUSER', data)
      rootState.userRegister.userRegisterData = data[0]
    },
    async reSendotp ({ commit }, newuser) {
      // console.log(["new user", newuser]);
      await repository.reSendOtp(newuser)
      // console.log(["Update user", data]);
      // commit("SET_NEWUSER", data);
    },
    async logout ({ commit }) {
      await repository.logout()
      commit('SET_USER', null)
      sessionStorage.clear()
      // sessionStorage.removeItem('user')
    },
    async NewUserValidation ({ commit }, newUserwithOtp) {
      const { data } = await repository.otp(newUserwithOtp)
      // console.log(["user value", data]);
      commit('SET_USER', data)
      sessionStorage.user = JSON.stringify(data)
    },
    async ResetPassword ({ commit }, mobile) {
      const { data } = await repository.resetPassword(mobile)
      // console.log(["user value", data]);
      commit('SET_RESET_USER', data)
    },
    async ResetOTP ({ state, commit }, userOTP) {
      const { data } = await repository.resetOTP(userOTP)
      // console.log(["user value", state.verifiedUser[0]]);
      commit('SET_RESET_USER', data)
      if (data.msg === 'ok') {
        commit('SET_NEWUSER', data)
      }
    },
    async SetPassword ({ commit }, UserwithPassword) {
      const { data } = await repository.setPassword(UserwithPassword)
      // console.log(["user value", data]);
      commit('SET_USER', data)
      commit('SET_NEWUSER', null)
      sessionStorage.user = JSON.stringify(data)
    },

    // Admin
    // async getBuyers ({ commit }, page) {
    //   const { data } = await adminrepository.getBuyers(page)
    //   commit('SET_BUYERS', data)
    // },
    asideMobileToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.getElementById('app').classList[isShow ? 'add' : 'remove']('ml-60', 'lg:ml-0')

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped')

      commit('basic', {
        key: 'isAsideMobileExpanded',
        value: isShow
      })
    },

    asideLgToggle ({ commit, state }, payload = null) {
      commit('basic', { key: 'isAsideLgActive', value: payload !== null ? payload : !state.isAsideLgActive })
    },

    fullScreenToggle ({ commit, state }, value) {
      commit('basic', { key: 'isFullScreen', value })

      document.documentElement.classList[value ? 'add' : 'remove']('full-screen')
    },

    darkMode ({ commit, state }) {
      const value = !state.darkMode

      document.documentElement.classList[value ? 'add' : 'remove']('dark')

      localStorage.setItem(darkModeKey, value ? '1' : '0')

      commit('basic', {
        key: 'darkMode',
        value
      })
    },

    // fetch ({ commit }, payload) {
    //   axios
    //     .get(adminrepository.getSellers())
    //     .then((r) => {
    //       if (r.data && r.data.data) {
    //         commit('basic', {
    //           key: payload,
    //           value: r.data.data
    //         })
    //       }
    //     })
    //     .catch(error => {
    //       alert(error.message)
    //     })
    // },
    // fetch ({ commit, dispatch }) {
    //   dispatch('policy/getPolicies')
    // },
    async deleteUser ({ commit }, user) {
      const { data } = await adminrepository.deleteUser(user)
      console.log(['user value......', data])
    }

  },
  modules: {
    admin, location, Useraddress, userRegister, shop, cart, category, review, menu, role, seller, sellerData, rider, riderData, buyer, order, policy, product, Notifications
  }
})
