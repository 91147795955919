import adminrepository from '@/api/adminRepository'
export const namespaced = true
export const state = {
  riders: [],
  activeRiders: [],
  lockRiders: [],
  riderAddress: null,
  riderContactperson: null,
  riderInfos: null
}
export const getters = {

}
export const mutations = {
  SET_RIDERS (state, riders) {
    state.riders = riders
  },
  SET_ACTIVE_RIDERS (state, activeRiders) {
    state.activeRiders = activeRiders
  },
  SET_LOCK_RIDERS (state, lockRiders) {
    state.lockRiders = lockRiders
  },
  SET_RIDER_ADDRESS (state, data) {
    state.riderAddress = data
  },
  SET_RIDER_CONTACT_PERSON (state, data) {
    state.riderContactperson = data
  },
  SET_INFOS (state, data) {
    state.riderInfos = data
  }
}
export const actions = {
  async getActiveRiders ({ commit }, page) {
    const { data } = await adminrepository.activeRiders(page)
    commit('SET_ACTIVE_RIDERS', data)
  },
  async searchActiveRiders ({ commit }, keyword) {
    const { data } = await adminrepository.searchActiveRiders(keyword)
    commit('SET_ACTIVE_RIDERS', data)
  },
  async getLockRiders ({ commit }, page) {
    const { data } = await adminrepository.lockRiders(page)
    commit('SET_LOCK_RIDERS', data)
  },
  async searchLockRiders ({ commit }, keyword) {
    const { data } = await adminrepository.searchLockRiders(keyword)
    commit('SET_LOCK_RIDERS', data)
  },
  async statusChange ({ commit }, user) {
    await adminrepository.statusChange(user)
  },
  async riderAddress ({ commit }, user) {
    const { data } = await adminrepository.riderAddress(user)
    commit('SET_RIDER_ADDRESS', data)
  },
  async riderContactPerson ({ commit }, user) {
    const { data } = await adminrepository.riderContactPerson(user)
    commit('SET_RIDER_CONTACT_PERSON', data)
  },
  async riderInformations ({ commit }, user) {
    const { data } = await adminrepository.riderInformation(user)
    commit('SET_INFOS', data)
  },
  async completedRides ({ commit }, user) {
    const { data } = await adminrepository.completedRides(user)
    return data
  }
}
