<script setup>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import '@/css/_app.css'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import FooterBar from '@/components/FooterBar.vue'
import Overlay from '@/components/Overlay.vue'

import NewOrderNotification from '@/components/AdminComponent/Rider/NewOrderNotification'
import ShopDirection from '@/components/AdminComponent/Rider/ShopDirection'
import PickedOrder from '@/components/AdminComponent/Rider/PickedOrder'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { firebase } from '@/firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const data = reactive({
  user: null,
  orders: [],
  order: null,
  cancelOrderIds: [],
  unsubscribe: null
})

const auth = getAuth()

const store = useStore()

store.commit('user', {
  name: 'John Doe',
  email: 'john@example.com',
  avatar: 'https://avatars.dicebear.com/api/avataaars/example.svg?options[top][]=shortHair&options[accessoriesChance]=93'
})

const isAsideLgActive = computed(() => store.state.isAsideLgActive)
const menu = computed(() => store.state.menu.Menus)
async function getMenu () {
  await store.dispatch('menu/getMenus', store.state.user[0].role_id)
}

const watchOrders = () => {
  if (!data.order) {
    const q = query(collection(firebase, 'orders'),
      where('status', '==', 1),
      where('selected_rider', '==', data.user.uid)
    )
    data.unsubscribe = onSnapshot(q, (querySnapshot) => {
      data.orders = []
      querySnapshot.forEach((doc) => {
        data.order = { id: doc.id, ...doc.data() }
        data.orderStatus = 'newOrder'
        data.orders.push({ id: doc.id, ...doc.data() })
      })
    })
    console.log('orders', data.orders)
  } else {
    data.unsubscribe()
    console.log('unsubscribe watchOrders')
  }
}
onMounted(() => {
  getMenu()
  onAuthStateChanged(auth, (user) => {
    if (user) {
      data.user = user
      // watchOrders()
    } else {
      console.log('no user found')
    }
  })
})

const overlayClick = () => {
  store.dispatch('asideLgToggle', false)
}
</script>
<template>
  <div class="flex flex-col min-h-screen pt-14 xl:pl-60 w-screen transition-position lg:w-auto">
    <nav-bar />
    <aside-menu :menu="menu" />
    <main
      v-if="data.order"
      class="h-full overflow-y-auto py-5 px-5 grow bg-indigo-50 grid place-items-center h-screen"
    >
      <NewOrderNotification
        v-if="data.orderStatus === 'newOrder'"
        :order="data.order"
        @CancelOrder="CancelOrder"
        @AcceptOrder="AcceptOrder"
      />
      <ShopDirection
        v-if="data.orderStatus === 'shopDirection'"
        :order="data.order"
        @ReachedLocation="ReachedLocation"
      />
      <PickedOrder
        v-if="data.orderStatus === 'pickedOrder'"
        :order="data.order"
        @OrderPicked="OrderPicked"
      />
    </main>
    <slot v-else />
    <footer-bar />
    <overlay
      v-show="isAsideLgActive"
      z-index="z-30"
      @overlay-click="overlayClick"
    />
  </div>
</template>
<style scoped>
.dropdown {
   cursor: pointer;
}
.m-clipped, .m-clipped body {
  overflow: hidden;
}
.full-screen body {
  padding: 0;
}
/* @media only screen and (min-width: 1200px) {
    .m-clipped, .m-clipped body {
        overflow: visible;
    }
} */
</style>
