<template>
  <div class="flex items-center justify-center lg:min-h-screen bg-gray-100">
    <div class="px-8 max-w-md text-left bg-white shadow-lg">
      <div class="flex justify-center pt-4">
        <router-link to="/">
          <img
            src="@/assets/dtl.png"
            alt=""
            class="w-20 h-20 text-blue-600"
            width="100px"
          >
        </router-link>
      </div>
      <h3 class="text-xl font-bold text-center">
        Enter OTP to reset your password
      </h3>
      <h6 class="text-l text-center">
        Enter 6 digit we sent to {{ $store.state.verifiedUser[0].mobile }}
      </h6>
      <form @submit.prevent="resetOTP">
        <div class="mt-4">
          <div>
            <label
              class="block"
              for="resetotp"
            >OTP</label>
            <input
              id="resetotp"
              v-model="state.resetotp"
              type="text"
              placeholder="OTP"
              class="
                w-full
                px-4
                py-2
                mt-2
                border
                rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600
              "
            >
            <span
              v-if="error"
              class="text-xs tracking-wide text-red-600"
            >
              {{ error }}</span>
            <span
              v-if="v$.resetotp.$error"
              class="text-xs tracking-wide text-red-600"
            >
              {{ v$.resetotp.$errors[0].$message }}</span>
          </div>
          <div class="flex items-baseline justify-between">
            <button
              class="
                px-6
                py-2
                mt-4
                text-white
                bg-blue-800
                rounded-lg
                hover:bg-blue-900
                w-full
              "
            >
              Submit
            </button>
            <!-- <a href="#" class="text-sm text-blue-600 hover:underline">Forgot password?</a> -->
          </div>
        </div>
      </form>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          OTP send again
          <button
            class="text-sm text-blue-600 hover:underline"
            @click.prevent="reSend"
          >
            Resend
          </button>
        </p>
      </div>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          <router-link
            to="/"
            class="px-6 py-2 text-sm text-blue-600 hover:underline"
          >
            Go to Home
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
export default {
  setup () {
    const state = reactive({
      resetotp: null
    })
    const rules = computed(() => {
      return {
        resetotp: { required, numeric }
      }
    })
    const v$ = useValidate(rules, state)
    return { state, v$ }
  },
  data () {
    return {
      // otp: null,
      error: null
    }
  },
  mounted () {
    // console.log(["Message", this.$store.state.registration.newuser.success]);
    document.title = this.$route.name
  },
  methods: {
    async resetOTP () {
      this.v$.$validate()
      if (!this.v$.$error) {
        this.error = null
        try {
          await this.$store.dispatch('ResetOTP', {
            id: this.$store.state.verifiedUser[0].id,
            otp: this.state.resetotp
          })
          // console.log(this.$store.state.verifiedUser.msg);
          // return;
          if (this.$store.state.verifiedUser.msg === 'ok') {
            await this.$router.push({ name: 'Set Password' })
          } else {
            this.error = this.$store.state.verifiedUser.msg
          }
        } catch (error) {
          this.error = error
        } finally {
          // this.loading = false;
        }
      } else {
        this.error = 'Please enter correct data'
      }
    },
    async reSend () {
      this.error = null
      try {
        await this.$store.dispatch('reSendotp', {
          id: this.$store.state.verifiedUser[0].id
        })
      } catch (error) {
        this.error = error
      } finally {
        // this.loading = false;
      }
    }
  }
}
</script>
