import repository from '../../api/repository.js'
export const namespaced = true
// const storage =SON.parse(sessionStorage.getItem("currentLocation"));
export const getters = {
  formatted_address: (state) => {
    return state.formatted_address
  },
  // lat: (state) => {
  //   return state.lat
  // },
  // lng: (state) => {
  //   return state.lng
  // },
  autocomplete: (state) => {
    return state.autocomplete
  },
  mapData: (state) => {
    return state.mapData
  },
  deliveryAddress: (state) => {
    return state.deliveryAddress
  }
}
export const state = {
  formatted_address: sessionStorage.currentLocation ? JSON.parse(sessionStorage.getItem('currentLocation')).formatted_address : '',
  // lat: localStorage.mapData ? JSON.parse(localStorage.getItem('mapData')).lat : null,
  // lng: localStorage.mapData ? JSON.parse(localStorage.getItem('mapData')).lng : null,
  autocomplete: [],
  mapData: localStorage.mapData ? JSON.parse(localStorage.getItem('mapData')) : null,
  deliveryAddress: localStorage.mapData ? { delivery: JSON.parse(localStorage.getItem('mapData')).address_description } : null,
  autoAddress: null,
  autoLat: null,
  autoLng: null,
  Latalngs: {
    device: {
      lat: null,
      lng: null
    },
    destination: {
      lat: localStorage.mapData ? JSON.parse(localStorage.getItem('mapData')).lat : null,
      lng: localStorage.mapData ? JSON.parse(localStorage.getItem('mapData')).lng : null
    },
    selectedLatLngFromMap: {
      lat: null,
      lng: null
    }
  },
  selectedAddress: 0
}
export const mutations = {
  SET_CurrentLocation (state, data) {
    state.formatted_address = data.formatted_address
    state.Latalngs.destination.lat = data.geometry.location.lat
    state.Latalngs.destination.lng = data.geometry.location.lng
    localStorage.mapData = JSON.stringify({
      address_description: data.formatted_address,
      lat: data.geometry.location.lat,
      lng: data.geometry.location.lng
    })
    state.mapData = {
      address_description: data.formatted_address,
      lat: data.geometry.location.lat,
      lng: data.geometry.location.lng
    }
  },
  SET_Autocomplete (state, data) {
    state.autocomplete = data
  },
  SET_SelectedAddress (state, result) {
    // state.formatted_address = result.formatted_address
    // state.lat = result.geometry.location.lat
    // state.lng = result.geometry.location.lng
    state.autoAddress = result
    state.autoLat = result.geometry.location.lat
    state.autoLng = result.geometry.location.lng
  },
  SET_DELIVERY_ADDRESS (state, address) {
    state.deliveryAddress = null
    state.deliveryAddress = address
  }

}
export const actions = {
  async getCurrentLocation ({ commit }, latlng) {
    const { data } = await repository.getCurrentLocation(latlng)
    // sessionStorage.currentLocation = JSON.stringify(data.results[0])
    // sessionStorage.currentLocation = data.results[0];
    commit('SET_CurrentLocation', data.results[0])
  },
  async getAutocompleteAddress ({ commit }, address) {
    const { data } = await repository.getAutocompleteAddress(address)
    commit('SET_Autocomplete', data.predictions)
  },
  async setSelectedAddress ({ commit }, placeId) {
    const { data } = await repository.setSelectedAddress(placeId)
    // sessionStorage.setItem('history', JSON.stringify(current));
    // sessionStorage.currentLocation = JSON.stringify(data.result)
    commit('SET_SelectedAddress', data.result)
  },
  setDeliveryAddress ({ commit }, address) {
    commit('SET_DELIVERY_ADDRESS', address)
  }

}
