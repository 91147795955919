<template>
  <Menu />
  <div class="flex-grow">
    <slot />
  </div>
  <Footer />
</template>
<script>
import Footer from '@/components/Site/Footer'
import Menu from '@/components/Site/Menu.vue'
export default {
  components: { Footer, Menu }
}
</script>
