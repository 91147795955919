/* eslint-disable no-undef */

import { ref } from 'vue'
import { useGmap } from '../composibles/useGmap'

const { loader } = useGmap()
let service = null

export async function useGetDistance (data) {
  const distanceData = ref(null)

  await loader.load()
  service = new google.maps.DistanceMatrixService()
  await service.getDistanceMatrix(
    {
      origins: [data.rider, data.shop],
      destinations: [data.shop, data.drop],
      travelMode: 'DRIVING'
    }, (response, status) => {
      distanceData.value = response
      // console.log('response, status', response, status)
    })

  return { distanceData }
}
