import adminrepository from '@/api/adminRepository'
import repository from '@/api/repository'
export const namespaced = true
export const state = {
  buyers: []
}
export const getters = {

}
export const mutations = {
//   SET_SELLERS (state, sellers) {
//     state.sellers = sellers
//   },
//   SET_ACTIVE_SELLERS (state, activeSeller) {
//     state.activeSeller = activeSeller
//   },
//   SET_LOCK_SELLERS (state, lockSeller) {
//     state.lockSeller = lockSeller
//   },
  SET_BUYERS (state, buyers) {
    state.buyers = buyers
  }
}
export const actions = {
  async getBuyers ({ commit }, page) {
    const { data } = await adminrepository.getBuyers(page)
    commit('SET_BUYERS', data)
  },
  async searchBuyers ({ commit }, keyword) {
    const { data } = await adminrepository.searchBuyers(keyword)
    commit('SET_BUYERS', data)
  },
  async getUser ({ commit }, user) {
    return await repository.getUser(user)
  }
//   async getSellers ({ commit }, page) {
//     const { data } = await adminrepository.getSellers(page)
//     commit('SET_SELLERS', data)
//   },
//   async getActiveSellers ({ commit }, page) {
//     const { data } = await adminrepository.activeSellers(page)
//     commit('SET_ACTIVE_SELLERS', data)
//   },
//   async searchActiveSellers ({ commit }, keyword) {
//     const { data } = await adminrepository.searchActiveSellers(keyword)
//     commit('SET_ACTIVE_SELLERS', data)
//   },
//   async getLockSellers ({ commit }, page) {
//     const { data } = await adminrepository.lockSellers(page)
//     commit('SET_LOCK_SELLERS', data)
//   },
//   async searchLockSellers ({ commit }, keyword) {
//     const { data } = await adminrepository.searchLockSellers(keyword)
//     commit('SET_LOCK_SELLERS', data)
//   },
//   async statusChange ({ commit }, user) {
//     await adminrepository.statusChange(user)
//   }
}
