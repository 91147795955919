<template>
  <div class="flex-none sticky top-0 z-50">
    <Disclosure
      v-slot="{ open }"
      as="nav"
      class="bg-gray-800"
    >
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
          <div class="flex flex-1 lg:flex-none">
            <div class="lg:hidden sm:block">
              <!-- Mobile menu button-->
              <DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span class="sr-only">Open main menu</span>
                <MenuIcon
                  v-if="!open"
                  class="block h-6 w-6"
                  aria-hidden="true"
                />
                <XIcon
                  v-else
                  class="block h-6 w-6"
                  aria-hidden="true"
                />
              </DisclosureButton>
            </div>
            <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div class="flex-shrink-0 flex items-center">
                <!--            <img class="block lg:hidden h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg" alt="Workflow" />-->
                <!--            <img class="hidden lg:block h-8 w-auto" src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg" alt="Workflow" />-->
                <router-link to="/">
                  <img
                    src="@/assets/dtl.png"
                    alt=""
                    class="block h-8 w-auto"
                  >
                </router-link>
              </div>
              <!--          <div class="hidden sm:block sm:ml-6">-->
              <!--            <div class="flex space-x-4">-->
              <!--              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>-->
              <!--            </div>-->
              <!--          </div>-->
            </div>
          </div>

          <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div class="hidden sm:block sm:ml-6">
              <div class="flex space-x-4">
                <router-link
                  v-if="authenticated && isBuyer"
                  to="/buyer/notifications"
                >
                  <button
                    type="button"
                    class="relative bg-gray-800 h-8 ml-2 p-1 rounded-full text-gray-400 hover:text-white
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800
                      focus:ring-white"
                  >
                    <span class="sr-only">Notifications</span>
                    <unicon
                      name="bell"
                      fill="#9ca3af"
                      class="hover:fill-white mt-1"
                    />
                    <Notifications />
                  </button>
                </router-link>
                <router-link
                  v-if="authenticated && isBuyer"
                  to="/buyer/trackOrder"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex"
                >
                  <SearchCircleIcon
                    class="h-6 w-6 pr-1"
                    aria-hidden="true"
                  />
                  <span>Track Order</span>
                </router-link>
                <router-link
                  v-if="authenticated && isBuyer"
                  to="/buyer/orders"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex"
                >
                  <ArchiveIcon
                    class="h-6 w-6 pr-1"
                    aria-hidden="true"
                  />
                  <span>Orders</span>
                </router-link>
                <router-link
                  v-if="authenticated && isBuyer"
                  to="/buyer/manageAccount"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex"
                >
                  <UserCircleIcon
                    class="h-6 w-6 pr-1"
                    aria-hidden="true"
                  />
                  <span>Manage Account</span>
                </router-link>
                <router-link
                  v-if="authenticated && !isBuyer"
                  :to="`/${user ? user.role : ''}/dashboard`"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex"
                >
                  <ArchiveIcon
                    class="h-6 w-6 pr-1"
                    aria-hidden="true"
                  />
                  <span>My Dashboard</span>
                </router-link>
                <div
                  v-if="!authenticated"
                  class="group text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex cursor-pointer"
                  @click.prevent="goToSignIn"
                >
                  <unicon
                    name="user-circle"
                    fill="#9ca3af"
                    class="group-hover:fill-white"
                  />
                  <span>Sign In</span>
                </div>
                <Menu
                  v-if="!authenticated"
                  as="div"
                  class="ml-3 relative"
                >
                  <div>
                    <MenuButton
                      class="bg-gray-800 flex text-sm group"
                      @click="isDownArrow = !isDownArrow"
                    >
                      <span
                        class="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium flex"
                      >
                        <unicon
                          name="user-circle"
                          fill="#9ca3af"
                          class="group-hover:fill-white"
                        />
                        <span>Sign Up</span>
                        <ChevronDownIcon
                          v-if="isDownArrow"
                          class="w-4 h-4 m-1"
                        />
                        <ChevronUpIcon
                          v-else
                          class="w-4 h-4 m-1"
                        />
                      </span>
                    </MenuButton>
                  </div>
                  <transition
                    class="z-10"
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                  >
                    <MenuItems
                      ref="signUpel"
                      class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      <MenuItem v-slot="{ active }">
                        <router-link
                          to="/signup"
                          :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                        >
                          <div class="flex">
                            <unicon
                              name="user-circle"
                              fill="#1f2937"
                              class="mr-1"
                            />
                            <span> As a Buyer/Customer</span>
                          </div>
                        </router-link>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <router-link
                          to="/seller/signup"
                          :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                        >
                          <div class="flex">
                            <unicon
                              name="user-circle"
                              fill="#1f2937"
                              class="mr-1"
                            />
                            <span> As a Seller</span>
                          </div>
                        </router-link>
                      </MenuItem>
                      <MenuItem v-slot="{ active }">
                        <router-link
                          to="/rider/signup"
                          :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                        >
                          <div class="flex">
                            <unicon
                              name="user-circle"
                              fill="#1f2937"
                              class="mr-1"
                            />
                            <div> As a Rider </div>
                          </div>
                        </router-link>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                </Menu>
              </div>
            </div>
            <!-- @click="getCartItems()" -->
            <router-link to="/buyer/cartList">
              <button
                type="button"
                class="relative bg-gray-800 h-8 ml-2 p-1 rounded-full text-gray-400 hover:text-white
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800
                      focus:ring-white"
              >
                <span class="sr-only">Shopping Cart</span>
                <unicon
                  name="shopping-cart"
                  fill="#9ca3af"
                  class="hover:fill-white"
                />
                <p class="absolute text-white rounded-full -top-3 -right-3 bg-red-400 w-6 h-6">
                  {{ cartData.length }}
                </p>
              </button>
            </router-link>
            <Menu
              v-if="authenticated"
              as="div"
              class="ml-3 relative"
            >
              <div>
                <MenuButton class="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                  <img
                    class="h-8 w-8 rounded-full"
                    src="@/assets/profile/profile.png"
                    alt=""
                  >
                  <span class="m-2 text-white hidden md:block">{{ user[0].name }}</span>
                </MenuButton>
              </div>
              <transition
                class="z-10"
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <!-- <MenuItem v-slot="{ active }" v-if="isBuyer">
                    <router-link
                      to="/manageAccount"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      Manage Account
                    </router-link>
                  </MenuItem> -->
                  <!-- <MenuItem v-slot="{ active }" v-if="isBuyer">
                    <router-link
                      to="/orders"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                    >
                      Orders
                    </router-link>
                  </MenuItem> -->
                  <MenuItem v-slot="{ active }">
                    <button
                      class="w-full"
                      :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']"
                      @click="logout"
                    >
                      Sign out
                    </button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <DisclosureButton
            v-for="item in navigation"
            :key="item.name"
            as="a"
            :href="item.href"
            :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium']"
            :aria-current="item.current ? 'page' : undefined"
          >
            {{ item.name }}
          </DisclosureButton>
        </div>
      </DisclosurePanel>
    </Disclosure>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { MenuIcon, SearchCircleIcon, XIcon, UserCircleIcon, ChevronDownIcon, ChevronUpIcon, ArchiveIcon } from '@heroicons/vue/outline'
import Notifications from '@/components/Site/Notifications'
import { mapState, mapGetters, mapMutations, mapActions } from '@/composibles/map-state'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
const store = useStore()
const router = useRouter()
const route = useRoute()
const loading = ref(false)
const error = ref(null)
const isDownArrow = ref(true)

const navigation = [
  { name: 'EN', href: 'shop', current: false },
  { name: 'BN', href: 'about', current: false },
  { name: 'Sign In', href: 'signup', current: false },
  { name: 'Sign Up', href: 'signup', current: false }
]

const { authenticated, isBuyer, user } = mapGetters() // computed(() => store.getters.authenticated)
const cartData = computed(() => store.getters['cart/cart_data'])
const cartItem = computed(() => store.getters['cart/cartItem'])

// const { user } = mapState() || null

const goToSignIn = () => {
  if (route.path === '/login' || route.path.search('signup') > -1) {
    router.push({ path: '/login' })
  } else {
    router.push({ path: '/login', query: { redirect: route.fullPath } })
  }
}

const getCartItems = async () => {
  if (authenticated && !cartItem.value) {
    await store.dispatch('cart/getCartItems', store.state.user[0].id)
  }
}

const logout = async () => {
  loading.value = true

  try {
    await store.dispatch('logout')
    await router.push('/')
  } catch (error) {
    error.value = error
  } finally {
    loading.value = false
  }
}

</script>
