import adminRepository from '@/api/adminRepository'
import repository from '@/api/repository'
export const namespaced = true
export const state = {
  wiselist: [],
  favourite: [],
  productMoreOptions: null
}
export const getters = {

}
export const mutations = {
  SET_FAVOURITE (state, favourite) {
    state.favourite = favourite
  },
  SET_PRODUCT_OPTIONS (state, options) {
    state.productMoreOptions = options
  }
}
export const actions = {
  async storeFavourite ({ commit }, product) {
    const { data } = await repository.storeFavourite(product)
    commit('SET_FAVOURITE', data)
  },
  async getFavourites ({ commit }, user) {
    const { data } = await repository.getFavourites(user)
    commit('SET_FAVOURITE', data)
  },
  async removeFavourite ({ commit }, favourite) {
    await repository.removeFavourite(favourite)
    // commit('SET_FAVOURITE', data)
  },
  async productOptions ({ commit }, product) {
    const { data } = await repository.productOptions(product)
    commit('SET_PRODUCT_OPTIONS', data)
  },
  async getCategoriesByProduct ({ commit }, product) {
    const { data } = await adminRepository.getCategoriesByProduct(product)
    return data
  }
}
