import repository from '@/api/repository'

export const namespaced = true
export const state = {
  lat: null,
  lng: null
}
export const getters = {
  latLng: (state) => {
    return { lat: state.lat, lng: state.lng }
  }
}
export const mutations = {

}
export const actions = {
  async sellerRegistration ({ commit, rootState }, seller) {
    const { data } = await repository.sellerRegistration(seller)
    rootState.userRegister.userRegisterData = data[0]
  }
}
