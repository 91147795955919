import repository from '../../api/repository.js'
import adminRepository from '@/api/adminRepository'
export const namespaced = true
export const getters = {
  alldeliveryreviews: (state) => {
    return state.deliveryreviews
  },
  Products: (state) => {
    return state.products
  },
  shoptype: (state) => {
    return state.shopType
  },
  dp: (state) => {
    return state.deliveryOrPickup
  },
  shopProduct: (state) => {
    return state.shopProduct
  },
  productEmpty: (state) => Object.keys(state.shopProduct).length === 0
}
export const state = {
  restaurants: sessionStorage.restaurents ? JSON.parse(sessionStorage.getItem('restaurents')) : [],
  products: {},
  shopproducts: {},
  hasProductsV: false,
  shopType: localStorage.st ? localStorage.getItem('st') : '',
  deliveryOrPickup: localStorage.dp ? localStorage.getItem('dp') : '',
  shopProduct: {},
  searchAbleData: null,
  sellerShop: null,
  searchRestaurent: null,
  shopDistanceFromDelivery: null,
  current_page: null,
  last_page: null,
  sellerProduct: 0,
  sellerShopProds: [],
  shopStaus: null,
  shopLatLng: {
    lat: null,
    lng: null
  },
  shopDistanceShippingCost: null,
  categories: [],
  deliveryreviews: []
}
export const mutations = {
  SET_RESTAURANT (state, data) {
    // if (Object.keys(state.products).length > 0) {
    //   const modifiedArr = data.filter((element) => {
    //     return element.id !== state.products.id
    //   })
    //   state.restaurants = [...state.restaurants, ...modifiedArr]
    // } else {
    //   const filteredResults = data.filter(item =>
    //     state.restaurants.every(val => item.id !== val.id)
    //   )
    //   state.restaurants = [...state.restaurants, ...filteredResults]
    //   // state.restaurants = [...state.restaurants, ...data]
    // }
    if (state.restaurants.length > 0) {
      const filteredResults = data.filter(item =>
        state.restaurants.every(val => item.id !== val.id)
      )
      state.restaurants = [...state.restaurants, ...filteredResults]
    } else {
      state.restaurants = [...state.restaurants, ...data]
    }
  },
  SET_CURRENT_PAGE (state, data) {
    state.current_page = data
  },
  SET_LAST_PAGE (state, data) {
    state.last_page = data
  },
  SET_SEARCH_RESTAURANT (state, data) {
    state.searchRestaurent = data
  },
  SET_SHOP_PRODUCTS (state, data) {
    console.log('new data....', data)
    // state.restaurants.push(data)
    if (state.restaurants.length > 0) {
      const itemExists = state.restaurants.some(el => el.id === data.id)
      if (!itemExists) {
        state.restaurants.push(data)
      }
    } else {
      state.restaurants.push(data)
    }
    state.products = data
    state.categories = data.category
    state.deliveryreviews = data.reviews
    state.hasProductsV = true
    state.shopproducts = data.products
  },
  SEARCH_SHOP_PRODUCTS (state, data) {
    state.hasProductsV = true
    state.shopproducts = data
  },
  SHOP_PRODUCT (state, data) {
    if (state.restaurants.length > 0) {
      const itemExists = state.restaurants.some(el => el.id === data.shop.id)
      if (!itemExists) {
        state.restaurants.push(data.shop)
      }
    } else {
      state.restaurants.push(data.shop)
    }
    state.shopProduct = data
  },
  SEARCHABLE_DATA (state, data) {
    state.searchAbleData = data
  },
  SET_SELLER_SHOP (state, data) {
    state.sellerShop = data
  },
  SET_SELLER_SHOP_PRODUCTS (state, data) {
    state.sellerShopProds = data.products
  },
  SET_ADVANCE_SEARCH_SHOP (state, data) {
    state.searchRestaurent = data
  }
}
export const actions = {
  async getRestaurant ({ commit }, param) {
    const urlParams = 'https://food.com/?' + param
    const url = new URL(urlParams)
    const page = url.searchParams.get('page')
    const newRes = state.restaurants.length / 12
    if (parseInt(page) > newRes) {
      const { data } = await repository.getRestaurant(param)
      commit('SET_RESTAURANT', data.data)
      commit('SET_CURRENT_PAGE', data.current_page)
      commit('SET_LAST_PAGE', data.last_page)
    }
  },
  async getShopProducts ({ commit }, param) {
    const { data } = await repository.getShopProducts(param)
    commit('SET_SHOP_PRODUCTS', data)
  },
  async getSellerShopProducts ({ commit }, param) {
    const { data } = await repository.getShopProducts(param)
    commit('SET_SELLER_SHOP_PRODUCTS', data)
  },
  async searchShopProducts ({ commit }, param) {
    const { data } = await repository.searchShopProducts(param.shop_id, param.keyword)
    commit('SEARCH_SHOP_PRODUCTS', data)
  },
  async ShopProduct ({ commit }, param) {
    const { data } = await repository.shopProduct(param)
    commit('SHOP_PRODUCT', data)
  },
  async allSearchable ({ commit }, keyword) {
    const { data } = await repository.allSearchable(keyword)
    commit('SEARCHABLE_DATA', data)
  },
  async allSearchRestaurent ({ commit }, keyword) {
    const { data } = await repository.allSearchRestaurent(keyword)
    commit('SET_SEARCH_RESTAURANT', data)
  },
  async storeShop ({ commit }, shopData) {
    const { data } = await adminRepository.storeShop(shopData)
    console.log(data)
  },
  async updteShop ({ commit }, shopData) {
    const { data } = await adminRepository.updteShop(shopData)
    commit('SET_SELLER_SHOP', data)
    console.log(data)
  },
  async getShopBySeller ({ commit }, seller) {
    const { data } = await adminRepository.getShopBySeller(seller)
    commit('SET_SELLER_SHOP', data)
  },
  async storeProduct ({ commit }, product) {
    const { data } = await adminRepository.storeProduct(product)
    console.log(data)
    // commit('SET_SELLER_SHOP', data)
  },
  async updateProduct ({ commit }, product) {
    const { data } = await adminRepository.updateProduct(product)
    return data
    // console.log(data)
    // commit('SET_SELLER_SHOP', data)
  },
  async getDistanceFromDelivery ({ commit, state }, param) {
    const { data } = await repository.getDistance(param)
    // console.log(data.rows[0].elements[0].duration.text, 'distance Time....')
    // console.log('data...', data, parseFloat(data.rows[0].elements[0].distance.text))
    state.shopDistanceFromDelivery = data.rows[0].elements[0].duration.text
    state.shopDistanceShippingCost = data.rows[0].elements[0].distance.text
    // commit('SET_SELLER_SHOP', data)
  },
  async advanceSearch ({ commit }, param) {
    const { data } = await adminRepository.advanceSearch(param)
    commit('SET_ADVANCE_SEARCH_SHOP', data)
  },
  async sellerProducts ({ commit, state }, param) {
    const { data } = await adminRepository.sellerProducts(param)
    state.sellerProduct = data
  },
  async updateStatusShop ({ commit, state }, shop) {
    const { data } = await adminRepository.updateStatusShop(shop)
    console.log(data)
    state.shopStaus = data.message
  },
  async getShopProductsCategory ({ commit, state }, param) {
    // console.log('from store..', param)
    const { data } = await repository.getShopProductsCategory(param.id, param.shop_id)
    state.shopproducts = data
  },
  async shopCompletedOrders ({ commit, state }, param) {
    // console.log('from store..', param)
    const { data } = await adminRepository.shopCompletedOrders(param)
    return data
  }

}
