import user from '@/router/middleware/user.js'
import AdminLayout from '@/Layouts/AdminLayout'

export default [
  {
    path: '/seller/create-shop',
    name: 'createShop',
    component: () => import(/* webpackChunkName: "createShop" */ '@/views/Admin/sellers/Shop/ShopInfo/CreateShop'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Shop'
    }
  },
  {
    path: '/seller/create-product',
    name: 'createProduct',
    component: () => import(/* webpackChunkName: "createShop" */ '@/views/Admin/sellers/Shop/ShopProductInfo/CreateShopProducts'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Products'
    }
  },
  {
    path: '/:role/create-categories',
    name: 'CreateProductCategories',
    component: () => import(/* webpackChunkName: "createCategory" */ '@/views/Admin/sellers/Shop/CreateProductCategories'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Product Category'
    }
  },
  {
    path: '/:role/list-categories',
    name: 'ProductCategoriesList',
    component: () => import(/* webpackChunkName: "categoryList" */ '@/views/Admin/sellers/Shop/ProductCategoriesList'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Product Category List'
    }
  },
  {
    path: '/seller/list-shop',
    name: 'listOfShop',
    component: () => import(/* webpackChunkName: "shopList" */ '@/views/Admin/sellers/Shop/ShopInfo/ListShop'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Shop List'
    }
  },
  {
    path: '/seller/list-product',
    name: 'listOfShopProduct',
    component: () => import(/* webpackChunkName: "productList" */ '@/views/Admin/sellers/Shop/ShopProductInfo/ListShopProducts'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Product List'
    }
  },
  {
    path: '/seller/product-reviews',
    name: 'ProductReviews',
    component: () => import(/* webpackChunkName: "productList" */ '@/views/Admin/sellers/Reviews/reviews'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Product Reviews'
    }
  },
  {
    path: '/seller/product-orders',
    name: 'ProductOrders',
    component: () => import(/* webpackChunkName: "productList" */ '@/views/Admin/sellers/Orders/orders'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Product Orders'
    }
  },
  {
    path: '/seller/product-completed-orders',
    name: 'ProductCompletedOrders',
    component: () => import(/* webpackChunkName: "productList" */ '@/views/Admin/sellers/CompletedOrder/CompletedOrders'),
    beforeEnter: user,
    meta: {
      layout: AdminLayout,
      title: 'Completed Orders'
    }
  }
]
