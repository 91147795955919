import repository from '@/api/repository'
import adminRepository from '@/api/adminRepository'
export const namespaced = true
export const state = {
  policy: {}
}
export const getters = {

}
export const mutations = {
  SET_POLICY (state, policyValue) {
    state.policy = policyValue
  }
}
export const actions = {
  async getPolicies ({ commit }) {
    const { data } = await repository.getPolicy()
    commit('SET_POLICY', data)
  },
  async storeImage ({ commit }, image) {
    const { data } = await adminRepository.storeImage(image)
    commit(data)
  },
  async updatePolicy ({ commit }, policy) {
    const { data } = await adminRepository.updatePolicy(policy)
    console.log(data)
    commit('SET_POLICY', data)
  }
}
