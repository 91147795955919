import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getMessaging } from 'firebase/messaging'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyCZ1CT3JvF5PEvCsuf8f6P9p8SObgVUcDs',
  authDomain: 'notification-bba3d.firebaseapp.com',
  projectId: 'notification-bba3d',
  storageBucket: 'notification-bba3d.appspot.com',
  messagingSenderId: '1008424088647',
  appId: '1:1008424088647:web:b68996d33ad458c903ed62'
}

const fbApp = initializeApp(firebaseConfig)

const auth = getAuth(fbApp)

const messaging = getMessaging(fbApp)

const firebase = getFirestore(fbApp)

export { firebaseConfig, fbApp, auth, firebase, messaging }
