import Login from '../views/Site/Authentication/Login.vue'
import OTP from '../views/Site/OTP.vue'
import AuthLayout from '../Layouts/AuthLayout.vue'
import LandingLayout from '../Layouts/LandingLayout.vue'
import HomeLayout from '../Layouts/HomeLayout.vue'
import guest from './middleware/guest.js'
import ResetPassword from '../views/Site/Authentication/ResetPassword.vue'
import ResetOTP from '../views/Site/Authentication/ResetOTP.vue'
import SetPassword from '../views/Site/Authentication/NewPassword.vue'
import NotFound from '../views/Site/NotFound/NotFound.vue'
import NetworkIssue from '../views/Site/NetworkIssue/NetworkIssue.vue'
import RouteA from '../router/routes/routeA'
import RouteB from '../router/routes/routeB'
import SellerRoute from '../router/routes/seller'
import BuyerRoute from '../router/routes/buyer'
import RiderRoute from '../router/routes/rider'
const routes = [
  ...RouteA,
  ...RouteB,
  ...SellerRoute,
  ...BuyerRoute,
  ...RiderRoute,
  {
    path: '/ha',
    name: 'Homea',
    component: () => import(/* webpackChunkName: "FoodHome" */'@/views/Site/Home'),
    // beforeEnter: guest,
    meta: {
      layout: HomeLayout
    }
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "FoodHome" */'@/views/Site/Food/Home'),
    // beforeEnter: guest,
    meta: {
      layout: LandingLayout
    }
  },
  {
    path: '/food',
    name: 'Food',
    component: () => import(/* webpackChunkName: "FoodHome" */'@/views/Site/Food/Home'),
    // beforeEnter: user,
    meta: {
      layout: LandingLayout
    }
  },
  {
    path: '/parcel',
    name: 'Parcel',
    component: () => import(/* webpackChunkName: "PercelHome" */'@/views/Site/Parcel/Home'),
    // beforeEnter: user,
    meta: {
      layout: LandingLayout
    }
  },
  {
    path: '/grocery',
    name: 'Grocery',
    component: () => import(/* webpackChunkName: "GroceryHome" */'@/views/Site/Grocery/Home'),
    // beforeEnter: user,
    meta: {
      layout: LandingLayout
    }
  },
  {
    path: '/otp',
    name: 'OTP',
    component: OTP,
    beforeEnter: guest,
    meta: {
      layout: AuthLayout,
      title: 'OTP'
    }
  },
  {
    path: '/resetpassword',
    name: 'Reset Password',
    component: ResetPassword,
    beforeEnter: guest,
    meta: {
      layout: AuthLayout,
      title: 'Reset Password'
    }
  },
  {
    path: '/resetotp',
    name: 'Submit OTP',
    component: ResetOTP,
    beforeEnter: guest,
    meta: {
      layout: AuthLayout,
      title: 'Reset OTP'
    }
  },
  {
    path: '/set-password',
    name: 'Set Password',
    component: SetPassword,
    beforeEnter: guest,
    meta: {
      layout: AuthLayout,
      title: 'Set Password'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: guest,
    meta: {
      layout: AuthLayout,
      title: 'Sign In'
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import(/* webpackChunkName: "signUpBuyer" */'@/views/Site/Authentication/signUpBuyer'),
    beforeEnter: guest,
    meta: {
      layout: AuthLayout,
      title: 'Sign Up'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/Site/About.vue'),
    meta: {
      layout: LandingLayout
    }
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "Shops" */'../views/Site/Shop.vue'),
    meta: {
      layout: LandingLayout,
      title: 'Restaurents'
    }
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import(/* webpackChunkName: "Login" */'@/views/Site/Authentication/Login'),
    meta: {
      title: 'Sign In'
    }
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
    meta: {
      title: '404'
    }
  },
  {
    path: '/network-issue',
    name: 'Network Issue',
    component: NetworkIssue,
    meta: {
      title: 'Network Failed'
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }
]

export default routes
