import repository from '../../api/repository.js'
import adminRepository from '@/api/adminRepository'
export const namespaced = true
export const getters = {}
export const state = {
  Menus: null,
  menuStatus: null,
  allMenu: null,
  menusRole: null,
  assignMenu: null
}
export const mutations = {
  SET_MENUS (state, menus) {
    state.menuStatus = menus.message
    state.Menus = menus[0]
  },
  SET_All_MENU (state, menus) {
    state.allMenu = menus
  },
  SET_MENUS_BY_ROLE (state, menus) {
    state.menuStatus = menus.message
    if (menus.message === 'success') {
      state.menusRole = menus[0]
      state.assignMenu = menus[1]
    } else {
      state.menusRole = null
      state.assignMenu = null
    }
  }
}
export const actions = {
  async getMenus ({ commit }, role) {
    const { data } = await repository.getMenus(role)
    commit('SET_MENUS', data)
  },
  async getMenusByRole ({ commit }, role) {
    const { data } = await repository.getMenus(role)
    commit('SET_MENUS_BY_ROLE', data)
  },
  async allMenus ({ commit }) {
    const { data } = await repository.allMenu()
    commit('SET_All_MENU', data)
  },
  async storeMenuRole ({ commit }, menuRole) {
    const { data } = await repository.storeMenuRole(menuRole)
    console.log(data)
  },
  async deleteMenu ({ commit }, menu) {
    const { data } = await adminRepository.deleteMenu(menu)
    console.log(data)
  },
  async storeMenu ({ commit }, menuData) {
    const { data } = await adminRepository.storeMenu(menuData)
    console.log(data)
  }
}
