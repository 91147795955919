<script setup>
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import NavBarItem from '@/components/NavBarItem.vue'
import NavBarItemLabel from '@/components/NavBarItemLabel.vue'
import NavBarMenu from '@/components/NavBarMenu.vue'
import NavBarMenuDivider from '@/components/NavBarMenuDivider.vue'
import UserAvatar from '@/components/UserAvatar.vue'
import Icon from '@/components/Icon.vue'
// import NavBarSearch from '@/components/NavBarSearch.vue'

const store = useStore()
const router = useRouter()

const toggleLightDark = () => {
  store.dispatch('darkMode')
}

const isNavBarVisible = computed(() => !store.state.isFullScreen)

const isAsideMobileExpanded = computed(() => store.state.isAsideMobileExpanded)

const userName = computed(() => store.state.user ? store.state.user[0].name : store.state.userName)

const menuToggleMobileIcon = computed(() => isAsideMobileExpanded.value ? 'angle-double-left' : 'list-ul')

const menuToggleMobile = () => store.dispatch('asideMobileToggle')

const isMenuNavBarActive = ref(false)

const menuNavBarToggleIcon = computed(() => isMenuNavBarActive.value ? 'times' : 'elipsis-double-v-alt')

const menuNavBarToggle = () => {
  isMenuNavBarActive.value = !isMenuNavBarActive.value
}

const menuOpenLg = () => {
  store.dispatch('asideLgToggle', true)
}
const logout = () => {
  store.dispatch('logout')
  router.push('/')
}
</script>

<template>
  <nav
    v-show="isNavBarVisible"
    class="top-0 left-0 right-0 fixed flex bg-white h-14 border-b border-gray-100 z-30 w-screen
    transition-position xl:pl-60 lg:w-auto lg:items-stretch dark:bg-gray-900 dark:border-gray-800"
    :class="{'ml-60 lg:ml-0':isAsideMobileExpanded}"
  >
    <div class="flex-1 items-stretch flex h-14">
      <!--      toggle icon-->
      <nav-bar-item
        type="flex lg:hidden toggle-icon"
        @click.prevent="menuToggleMobile"
      >
        <!-- <icon
          :path="menuToggleMobileIcon"
          size="24"
        /> -->
        <unicon
          :name="menuToggleMobileIcon"
          fill="royalblue"
          class="transition-colors"
        />
      </nav-bar-item>
      <!--      end toggle icon-->
      <nav-bar-item
        type="hidden lg:flex xl:hidden"
        @click.prevent="menuOpenLg"
      >
        <!-- <icon
          :path="mdiMenu4"
          size="24"
        /> -->
        <unicon
          name="bars"
          fill="royalblue"
          class="transition-colors"
        />
      </nav-bar-item>
      <!-- <nav-bar-item>
        <nav-bar-search />
      </nav-bar-item> -->
    </div>
    <div class="flex-none items-stretch flex h-14 lg:hidden three-dot">
      <!--      three dot-->
      <nav-bar-item @click.prevent="menuNavBarToggle">
        <!-- <icon
          :path="menuNavBarToggleIcon"
          size="24"
        /> -->
        <unicon
          :name="menuNavBarToggleIcon"
          fill="royalblue"
          class="transition-colors"
        />
      </nav-bar-item>
      <!--      end dot-->
    </div>
    <div
      class="absolute w-screen top-14 left-0 bg-white shadow
        lg:w-auto lg:items-stretch lg:flex lg:grow lg:static lg:border-b-0 lg:overflow-visible lg:shadow-none dark:bg-gray-900"
      :class="[isMenuNavBarActive ? 'block' : 'hidden']"
    >
      <div
        class="max-h-screen-menu overflow-y-auto lg:overflow-visible lg:flex lg:items-stretch lg:justify-end lg:ml-auto"
      >
        <!-- <nav-bar-menu has-divider>
          <nav-bar-item-label
            :icon="mdiMenu"
            label="Sample menu"
          />

          <template #dropdown>
            <nav-bar-item>
              <nav-bar-item-label
                :icon="mdiClockOutline"
                label="Item One"
              />
            </nav-bar-item>
            <nav-bar-item>
              <nav-bar-item-label
                :icon="mdiCloud"
                label="Item Two"
              />
            </nav-bar-item>
            <nav-bar-menu-divider />
            <nav-bar-item>
              <nav-bar-item-label
                :icon="mdiCrop"
                label="Item Last"
              />
            </nav-bar-item>
          </template>
        </nav-bar-menu> -->
        <nav-bar-menu has-divider>
          <user-avatar class="w-6 h-6 mr-3 inline-flex" />
          <div>
            <span>{{ userName }}</span>
          </div>

          <template #dropdown>
            <nav-bar-item to="/profile">
              <nav-bar-item-label
                :icon="'user-square'"
                label="My Profile"
              />
            </nav-bar-item>
            <!-- <nav-bar-item>
              <nav-bar-item-label
                :icon="mdiCogOutline"
                label="Settings"
              />
            </nav-bar-item> -->
            <!-- <nav-bar-item>
              <nav-bar-item-label
                :icon="mdiEmail"
                label="Messages"
              />
            </nav-bar-item> -->
            <nav-bar-menu-divider />
            <nav-bar-item @click.prevent="logout">
              <nav-bar-item-label
                icon="signout"
                label="Log Out"
              />
            </nav-bar-item>
          </template>
        </nav-bar-menu>
        <nav-bar-item
          has-divider
          is-desktop-icon-only
          @click.prevent="toggleLightDark"
        >
          <nav-bar-item-label
            :icon="'moon'"
            label="Light/Dark"
            is-desktop-icon-only
          />
        </nav-bar-item>
        <!--        <nav-bar-item-->
        <!--          href="https://github.com/justboil/admin-one-vue-tailwind"-->
        <!--          has-divider-->
        <!--          is-desktop-icon-only-->
        <!--        >-->
        <!--          <nav-bar-item-label-->
        <!--            :icon="mdiGithub"-->
        <!--            label="GitHub"-->
        <!--            is-desktop-icon-only-->
        <!--          />-->
        <!--        </nav-bar-item>-->
        <nav-bar-item
          is-desktop-icon-only
          @click.prevent="logout"
        >
          <nav-bar-item-label
            :icon="'signout'"
            label="Log out"
            is-desktop-icon-only
          />
        </nav-bar-item>
      </div>
    </div>
  </nav>
</template>
