import repository from '../../api/repository.js'
export const namespaced = true
export const state = {
  user_addresses: sessionStorage.address
    ? JSON.parse(sessionStorage.getItem('address'))
    : { data: [] },
  userAddressData: []
}
export const getters = {
  hasAddressdata: (state) => state.userAddressData ?? state.user_addresses.data.length !== 0,
  gAddressData: (state) => {
    return state.user_addresses
  },
  allAddressData: (state) => {
    return state.user_addresses
  },
  defaultAddress: (state) => {
    if (state.user_addresses.data !== 0) {
      return state.user_addresses.data.find(a => a.isDefault === 1)
    }
  }
}
export const mutations = {
  SET_Address (state, addresses) {
    state.user_addresses = addresses.address
  }
}
export const actions = {
  async getUserAddresses ({ commit }, userid) {
    const { data } = await repository.getUserAddress(userid)
    sessionStorage.removeItem('address')
    sessionStorage.address = JSON.stringify(data.address)
    commit('SET_Address', data)
  },
  async getUserAllAddresses ({ commit, state }, userid) {
    const { data } = await repository.getUserAddress(userid)
    state.userAddressData = data
    // commit('SET_Address', data)
  },
  async changeUserAddresses ({ commit }, param) {
    const { data } = await repository.changeAddress(param)
    sessionStorage.removeItem('address')
    sessionStorage.address = JSON.stringify(data.address)
    commit('SET_Address', data)
  },
  async addUserAddresses ({ commit }, address) {
    const { data } = await repository.addAddress(address)
    sessionStorage.removeItem('address')
    sessionStorage.address = JSON.stringify(data.address)
    commit('SET_Address', data)
    return data.currentAddress
  },
  async removeUserAddresses ({ commit }, address) {
    const { data } = await repository.removeAddress(address)
    sessionStorage.removeItem('address')
    sessionStorage.address = JSON.stringify(data.address)
    commit('SET_Address', data)
  },
  async getProduct ({ commit }, productId) {
    await repository.getProduct(productId)
  }

}
