import adminRepository from '@/api/adminRepository'
import repository from '@/api/repository'
export const namespaced = true
export const state = {
  orders: [],
  completedorders: [],
  userOrdersHistory: [],
  currentOrders: [],
  trackingOrders: [],
  trackingError: null,
  orderDetails: [],
  sellerCurrentOrders: null,
  sellerAllOrders: null,
  trackingStatus: []
}
export const getters = {

}
export const mutations = {
  SET_ORDERS (state, orders) {
    console.log('orders.............?', orders)
    state.orders = orders
  },
  SET_TRACKIMG_ORDERS (state, order) {
    console.log('Order...', order.order)
    if (order.message === 'success') {
      state.trackingError = null
      state.trackingOrders = order.order
      state.trackingStatus = order.notifications
    } else {
      state.trackingOrders = []
      state.trackingStatus = []
      state.trackingError = order.message
    }
  },
  SET_ORDER_DETAILS (state, data) {
    state.orderDetails = data
  }

}
export const actions = {
  async storeOrder ({ commit }, order) {
    return await repository.storeOrder(order)
  },
  async getOrders ({ commit, rootState }, page) {
    const { data } = await repository.getOrders(page)
    rootState.admin.pendingOrder = true
    commit('SET_ORDERS', data)
  },
  async searchOrders ({ commit }, keyword) {
    const { data } = await repository.searchOrders(keyword)
    commit('SET_ORDERS', data)
  },
  async getOrdersHistoryByUser ({ commit, state }, user) {
    const { data } = await repository.getOrdersHistoryByUser(user)
    state.userOrdersHistory = data
  },
  async getCurrentOrdersByUser ({ commit, state }, user) {
    const { data } = await repository.getCurrentOrdersByUser(user)
    state.currentOrders = data
  },
  async trackingOrders ({ commit, state }, orderNo) {
    const { data } = await repository.trackingOrders(orderNo)
    commit('SET_TRACKIMG_ORDERS', data)
  },
  async orderDetails ({ commit }, id) {
    const { data } = await adminRepository.orderDetails(id)
    commit('SET_ORDER_DETAILS', data)
  },
  async sellerOrders ({ commit, state }, seller) {
    const { data } = await adminRepository.sellerOrders(seller)
    state.sellerCurrentOrders = data[0]
    state.sellerAllOrders = data[1]
  },
  async allCompletedOrders ({ commit, state }, page) {
    const { data } = await adminRepository.allCompletedOrders(page)
    state.completedorders = data
    return data
  },
  async cancelOrder ({ commit, state }, payload) {
    const { data } = await adminRepository.cancelOrder(payload)
    return data
  }

}
