import api from './api'
export default {
  getSellers (param) {
    return api.get(`/api/sellers?page=${param.page}`)
  },
  activeSellers (param) {
    return api.get(`/api/active-seller?page=${param.page}`)
  },
  searchActiveSellers (param) {
    return api.get(`/api/search-active-seller/${param}`)
  },
  lockSellers (param) {
    return api.get(`/api/locked-seller?page=${param.page}`)
  },
  searchLockSellers (param) {
    return api.get(`/api/search-locked-seller/${param}`)
  },
  activeRiders (param) {
    return api.get(`/api/active-rider?page=${param.page}`)
  },
  searchActiveRiders (param) {
    return api.get(`/api/search-active-rider/${param}`)
  },
  searchBuyers (param) {
    return api.get(`/api/search-buyers/${param}`)
  },
  lockRiders (param) {
    return api.get(`/api/locked-rider?page=${param.page}`)
  },
  searchLockRiders (param) {
    return api.get(`/api/search-locked-rider/${param}`)
  },
  riderAddress (param) {
    return api.get(`/api/rider-address/${param}`)
  },
  riderContactPerson (param) {
    return api.get(`/api/rider-contact-person/${param}`)
  },
  getBuyers (param) {
    return api.get(`/api/buyers?page=${param.page}`)
  },
  statusChange (param) {
    return api.post('/api/status-change', param)
  },
  deleteUser (param) {
    return api.delete(`/api/users/${param}`)
  },
  saveRole (param) {
    return api.post('/api/store-role', param)
  },
  getRoles () {
    return api.get('/api/get-roles')
  },
  updateRole (param) {
    return api.put('/api/update-role', param)
  },
  deleteRole (param) {
    return api.delete(`/api/delete-role/${param}`)
  },
  deleteMenu (param) {
    return api.delete(`/api/delete-menu/${param}`)
  },
  storeMenu (param) {
    return api.post('/api/store-menu', param)
  },
  storeImage (param) {
    return api.post('/api/store-image', param)
  },
  storeShop (param) {
    return api.post('/api/store-shop', param)
  },
  updteShop (param) {
    return api.put('/api/update-shop', param)
  },
  storeCategory (param) {
    return api.post('/api/store-category', param)
  },
  getShopBySeller (seller) {
    return api.get(`/api/get-shop-by-seller/${seller}`)
  },
  getCategories (param) {
    return api.get(`/api/get-categories?page=${param.page}`)
  },
  storeProduct (product) {
    return api.post('/api/store-product', product)
  },
  updateProduct (product) {
    return api.post('/api/update-product', product)
  },
  updateCategories (categoryData) {
    return api.put(`/api/update-categories/${categoryData.id}`, categoryData)
  },
  getPopularCategories () {
    return api.get('/api/get-popular-categories')
  },
  updatePopularCategories (category) {
    return api.post('/api/update-popular-categories', category)
  },
  advanceSearch (param) {
    return api.get(`/api/advance-search?rating=${param.rating}&popularity=${param.popularity}&lat=${param.latLng.lat}&lng=${param.latLng.lng}`)
  },
  orderDetails (id) {
    return api.get(`/api/order-details/${id}`)
  },
  sellerProductReviews (id) {
    return api.get(`/api/seller-product-reviews/${id}`)
  },
  shopProductReviews (shop) {
    return api.get(`/api/shop-product-reviews/${shop}`)
  },
  productReviews (product) {
    return api.get(`/api/product-reviews/${product}`)
  },
  replyReview (param) {
    return api.post('/api/reply-review', param)
  },
  removeReplyReview (id) {
    return api.delete(`/api/remove-reply-review/${id}`)
  },
  sellerProducts (seller) {
    return api.get(`/api/seller-products/${seller}`)
  },
  sellerOrders (seller) {
    return api.get(`/api/seller-orders/${seller}`)
  },
  riderInformation (rider) {
    return api.get(`/api/rider-info/${rider}`)
  },
  updateStatusShop (shop) {
    return api.put(`/api/update-status-shop/${shop}`)
  },
  updatePolicy (policy) {
    return api.put('/api/update-policy', policy)
  },
  getCategoriesByProduct (product) {
    return api.get(`/api/get-categories-by-product/${product}`)
  },
  completedRides (rider) {
    return api.get(`/api/completed-rides/${rider.user}?page=${rider.page}`)
  },
  allCompletedOrders (page) {
    return api.get(`/api/all-completed-orders?page=${page}`)
  },
  getAdminDashboardCount (page) {
    return api.get('/api/get-admin-dashboard-count')
  },
  shopCompletedOrders (param) {
    return api.get(`/api/shop-completed-orders/${param.shop}`)
  },
  searchCompletedOrdersByDate (param) {
    return api.post('/api/search-completed-orders-by-date', param)
  },
  searchPendingOrdersByDate (param) {
    return api.post('/api/search-pending-orders-by-date', param)
  },
  searchCompletedOrdersByNo (param) {
    return api.get(`/api/search-completed-orders-by-ordrerNo/${param}`)
  },
  searchPendingOrdersByNo (param) {
    return api.get(`/api/search-pending-orders-by-ordrerNo/${param}`)
  },
  getFavouriteProduct (param) {
    return api.get(`/api/get-favourite-products/${param}`)
  },
  cancelOrder (param) {
    return api.post('/api/cancel-order', param)
  }
}
