<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Level from '@/components/Level.vue'

const store = useStore()

const year = computed(() => new Date().getFullYear())

const isFooterBarVisible = computed(() => !store.state.isFullScreen)
</script>

<template>
  <footer
    v-show="isFooterBarVisible"
    class="bg-white py-2 px-6 dark:bg-gray-900"
  >
    <level>
      <div class="text-center md:text-left">
        <b>&copy; {{ year }},
          <router-link
            to="/"
          >Darco Technologies Limited</router-link>.
        </b>
      </div>
      <div class="md:py-2">
        <router-link to="/">
          <!--          <jb-logo class="w-auto h-8 md:h-6" />-->
          <img
            src="@/assets/dtl.png"
            alt=""
            class="w-auto h-8 md:h-12"
          >
        </router-link>
      </div>
    </level>
  </footer>
</template>
