import adminRepository from '@/api/adminRepository'
export const namespaced = true
export const state = {
  roles: null
}
export const getters = {
}
export const mutations = {
  SET_ROLES (state, data) { state.roles = data }
}
export const actions = {
  async saveRole ({ commit }, role) {
    const { data } = await adminRepository.saveRole(role)
    commit('SET_ROLES', data)
  },
  async getRoles ({ commit }) {
    const { data } = await adminRepository.getRoles()
    commit('SET_ROLES', data)
  },
  async updateRole ({ commit }, role) {
    await adminRepository.updateRole(role)
  },
  async deleteRole ({ commit }, role) {
    await adminRepository.deleteRole(role)
  }
}
