<template>
  <div class="flex items-center justify-center lg:min-h-screen bg-gray-100">
    <div class="px-8 max-w-md text-left">
      <h1>Uh-oh!</h1>
      <h3>
        It looks like you're experiening some network issues,please click home
        button & try again
      </h3>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          <router-link
            to="/"
            class="text-sm text-blue-600 hover:underline"
          >
            Go to Home
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  mounted () {
    document.title = this.$route.name
  }
}
</script>
