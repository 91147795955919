<template>
  <div class="flex items-center justify-center">
    <div class="rounded border p-2 shadow-md w-full bg-white">
      <div
        class="flex w-full items-center justify-between cursor-pointer"
        @click="isClose = !isClose"
      >
        <slot name="header" />
        <unicon
          name="angle-up"
          fill="royalblue"
          :class="isClose ? 'rotate-180' : 'rotate-0'"
        />
      </div>
      <div
        v-show="!isClose"
        class="my-2"
      >
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
  collapse: {
    type: Boolean,
    default: true
  }
})

const isClose = ref(props.collapse)

</script>
