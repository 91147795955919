<template>
  <div
    class="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800"
  >
    <div
      v-show="data.showMap"
      ref="directionMap"
      class="w-full h-56 bg-red-400"
    />

    <div class="flex justify-between px-2 py-2 bg-yellow-500">
      <h1
        class="mx-3 py-1 w-full text-lg text-center font-semibold text-gray-900"
      >
        New Order
      </h1>
      <button
        class="px-3 py-1 rounded text-lg text-center font-semibold focus:outline-none"
        :class="[
          data.showMap ? 'bg-orange-600 text-white' : 'bg-white text-gray-900'
        ]"
        @click="showDirectionMap"
      >
        Direction
      </button>
    </div>

    <div class="px-6 py-4">
      <div class="border border-gray-200 rounded-md p-2">
        <h1
          class="uppercase font-thin text-center text-gray-800 dark:text-white"
        >
          pickup from
        </h1>
        <h1
          class="text-lg mt-2 uppercase font-semibold text-gray-800 dark:text-white"
        >
          {{ orderData.restaurentData.name }}
        </h1>

        <p class="py-1 text-gray-700 dark:text-gray-400">
          {{ orderData.restaurentData.address }}
        </p>

        <div class="flex items-center text-gray-700 dark:text-gray-200">
          <unicon name="clock" fill="royalblue" />
          <h1 class="px-2 text-sm">{{ data.trip.duration }} away</h1>
        </div>
      </div>
      <div class="border border-gray-200 rounded-md my-2">
        <div class="p-2 text-center uppercase font-thin">
          Trip distance {{ data.trip.tripDistance }} km
        </div>
        <div class="flex w-full justify-between border-t border-gray-200">
          <div class="text-center w-full p-2 border-r">
            Pickup: {{ data.trip.pickup }}
          </div>
          <div class="text-center w-full p-2">Drop: {{ data.trip.drop }}</div>
        </div>
      </div>
      <div class="border border-gray-200 bg-gray-50 rounded-md p-2">
        <h1
          class="uppercase font-semibold text-center text-gray-500 dark:text-white"
        >
          Delivered To
        </h1>
        <p class="py-1 text-gray-700 dark:text-gray-400">
          {{ typeof orderData.deliveryAddress.delivery == 'string' ? orderData.deliveryAddress.delivery : orderData.deliveryAddress.delivery.address }}
        </p>
      </div>
    </div>
    <div class="flex items-center justify-between bg-gray-900">
      <button
        class="px-4 py-2 w-full font-medium text-white uppercase transition-colors duration-200 transform bg-red-600 rounded-bl hover:bg-red-500 focus:outline-none"
        @click="$emit('CancelOrder', props.order.id)"
      >
        Cancle
      </button>
      <button
        class="w-full px-4 py-2 font-medium text-white uppercase transition-colors duration-200 transform bg-green-600 rounded-br hover:bg-green-500 focus:outline-none"
        @click="$emit('AcceptOrder', props.order.id)"
      >
        Accept
      </button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-undef */
import { useGeolocation } from '@vueuse/core'
import { useGetDistance } from '@/composibles/useGetDistance'
import { onMounted, ref, toRef } from 'vue'
import { useGmap } from '@/composibles/useGmap'
const { loader } = useGmap()

const emit = defineEmits(['AcceptOrder', 'CancelOrder'])

const { coords, locatedAt, error } = useGeolocation()
console.log(
  'coords, locatedAt, error ',
  coords.value,
  locatedAt.value,
  error.value
)

let map = null
const props = defineProps({
  order: {
    type: Array,
    default: () => []
  }
})
const orderData = toRef(props, 'order')
console.log('props Order', orderData.value.restaurentData.length)
const directionMap = ref(null)
const directionsService = ref(null)
const directionsRenderer = ref(null)
// const marker = ref(null)
const { lat: slat, lng: slng } = orderData.value.restaurentData
const { lat: dlat, lng: dlng } = orderData.value.shippingCoords

console.log('new Order props, ', orderData.value)

const data = ref({
  shop: { lat: slat, lng: slng }, // Restaurant
  rider: { lat: dlat, lng: dlng }, // Rider
  drop: { lat: dlat, lng: dlng }, // Drop Shipping
  trip: {
    tripDistance: '',
    pickup: '',
    drop: '',
    duration: ''
  },
  showMap: false
})

const showDirectionMap = async () => {
  data.value.showMap = !data.value.showMap
  if (data.value.showMap) {
    if (!map) {
      await directions()
    }
  }
}

const orderDistance = async () => {
  const { distanceData } = await useGetDistance(data.value)
  const discanceMatrix = distanceData.value.rows
    .map((rows) => {
      return rows.elements.filter((e) => e.distance.value > 100)
    })
    .flat()
  data.value.trip.tripDistance = (
    (discanceMatrix[0].distance.value + discanceMatrix[1].distance.value) /
    1000
  ).toFixed(2)
  data.value.trip.pickup = discanceMatrix[0].distance.text
  data.value.trip.drop = discanceMatrix[1].distance.text
  data.value.trip.duration = discanceMatrix[0].duration.text
}

const directions = async () => {
  await loader.load()
  const shopWay = []
  if (orderData.value.restaurentData.length > 1) {
    orderData.value.restaurentData.map((res) => {
      shopWay.push({ location: { lat: res.lat, lng: res.lng } })
      return true
    })
  } else {
    shopWay.push({ location: data.value.shop })
  }

  map = new google.maps.Map(directionMap.value, {
    center: data.value.rider,
    disableDefaultUI: true
  })
  directionsService.value = new google.maps.DirectionsService()
  directionsRenderer.value = new google.maps.DirectionsRenderer()
  directionsRenderer.value.setMap(map)
  const route = {
    origin: data.value.rider,
    destination: data.value.drop,
    waypoints: shopWay,
    optimizeWaypoints: true,
    travelMode: google.maps.TravelMode.DRIVING
  }
  console.log('route new Order', route)
  directionsService.value
    .route(route)
    .then((response) => {
      console.log('response Directions', response)
      directionsRenderer.value.setDirections(response)
    })
    .catch((e) => {
      console.log('Directions request failed due to ' + e)
    })
}

onMounted(async () => {
  await orderDistance()
})
</script>
