import LandingLayout from '@/Layouts/LandingLayout'

export default [
  {
    path: '/addressCreate',
    component: () => import('@/views/Site/AddressesCreate')
  },
  {
    path: '/fcm',
    component: () => import(/* webpackChunkName: "Notification" */ '@/views/Site/FCM/Notification'),
    name: 'Notification',
    meta: {
      layout: LandingLayout,
      title: 'Fcm Notification'
    }
  },
  {
    path: '/geolocation',
    component: () => import(/* webpackChunkName: "FMS" */ '@/geolocation/Map'),
    name: 'geolocation'
  }
]
