<template>
  <div class="flex items-center justify-center lg:min-h-screen bg-gray-100">
    <div class="px-8 py-6 mt-4 text-left bg-white shadow-lg">
      <div class="flex justify-center">
        <router-link to="/">
          <img
            src="@/assets/dtl.png"
            alt=""
            class="w-20 h-20 text-blue-600"
          >
        </router-link>
      </div>
      <h3 class="text-2xl font-bold text-center">
        Login to your account
      </h3>
      <span
        v-if="$store.state.error"
        class="text-xs tracking-wide text-red-600"
      >{{ $store.state.error }}</span>
      <form @submit.prevent="signIn">
        <div class="mt-4">
          <div>
            <label
              class="block"
              for="email"
            >Mobile or Email</label>
            <input
              v-model="state.user.email"
              type="text"
              placeholder="Mobile/Email"
              class="
                w-full
                px-4
                py-2
                mt-2
                border
                rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600
              "
            >
            <span
              v-if="v$.user.email.$error"
              class="text-xs tracking-wide text-red-600"
            >{{ v$.user.email.$errors[0].$message }}
            </span>
          </div>
          <div class="mt-4">
            <label class="block">Password</label>
            <input
              v-model="state.user.password"
              type="password"
              placeholder="Password"
              class="
                w-full
                px-4
                py-2
                mt-2
                border
                rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600
              "
            >
            <span
              v-if="v$.user.password.$error"
              class="text-xs text-red-600"
            >{{ v$.user.password.$errors[0].$message }}
            </span>
          </div>
          <div class="flex items-baseline justify-between">
            <button
              class="
                px-6
                py-2
                mt-4
                text-white
                bg-blue-600
                rounded-lg
                hover:bg-blue-900
              "
            >
              {{ loading ? "Login.." : "Login" }}
            </button>
            <router-link
              to="resetpassword"
              class="text-sm text-blue-600 hover:underline"
            >
              Forgot password?
            </router-link>
          </div>
        </div>
      </form>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          You don't have any account?
          <router-link
            to="/signup"
            class="text-sm text-blue-600 hover:underline"
          >
            Sign Up
          </router-link>
        </p>
      </div>
      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          <router-link
            to="/"
            class="text-sm text-blue-600 hover:underline"
          >
            Go to Home
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import useValidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { reactive, computed, inject } from 'vue'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { fbApp, firebase } from '@/firebase'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { doc, setDoc } from 'firebase/firestore'
const router = useRouter()
const route = useRoute()
const swal = inject('$swal')

const store = useStore()

const state = reactive({
  user: {
    email: '',
    password: '',
    device_name: 'web'
  },
  loading: false,
  error: null
})
const rules = computed(() => {
  return {
    user: {
      email: { required },
      password: { required }
    }
  }
})
const v$ = useValidate(rules, state)
const role = computed(() => store.state.user ? store.state.user[0].role.toLowerCase() : null)
const signIn = async () => {
  v$.value.$validate()
  if (!v$.value.$error) {
    state.error = null
    try {
      await store.dispatch('login', state.user)
      if (store.state.newuser !== null && store.state.newuser.msg === 'notVerified') {
        if (store.state.newuser[0].role === 'Buyer') {
          await router.push('/otp')
        } else {
          swal.fire({
            toast: true,
            icon: 'error',
            title: 'Please contact to administration for you verification',
            animation: true,
            position: 'top-right',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true
          })
        }
      } else {
        await fbLogin()
        console.log('fbLogin()')
        if (role.value === 'buyer') {
          console.log('role.value === buyer')
          await router.push(route.query.redirect || '/')
        } else {
          console.log('router.push')
          await router.push(`/${role.value}/dashboard`)
        }
      }
    } catch (error) {
      state.error = error
    } finally {
      state.loading = false
    }
  } else {
    console.log('failed')
  }
}
const fbLogin = async () => {
  const auth = getAuth(fbApp)

  await signInWithEmailAndPassword(auth, store.state.user[0].email, state.user.password)
    .then((userCredential) => {
      console.log('User login from firebase', userCredential)
      saveMessagingDeviceToken()
    })
    .catch((error) => {
      console.log(error.code, error.message)
    })

  onMessage(getMessaging(fbApp), (message) => {
    console.log(
      'New foreground notification from Firebase Messaging!',
      message.notification
    )
  })
}

async function saveMessagingDeviceToken () {
  let currentToken = localStorage.getItem('currentToken')
  if (!currentToken) {
    try {
      currentToken = await getToken(getMessaging())
      if (currentToken) {
        localStorage.setItem('currentToken', currentToken)
        console.log('Got FCM device token:', currentToken)
        // Saving the Device Token to Cloud Firestore.
        const tokenRef = doc(firebase, 'fcmTokens', currentToken)
        await setDoc(
          tokenRef,
          { uid: getAuth().currentUser.uid },
          { merge: true }
        )
      } else {
        // Need to request permissions to show notifications.
        await requestNotificationsPermissions()
      }
    } catch (error) {
      console.error('Unable to get messaging token.', error)
    }
  }
}

// Notification permission granted.
async function requestNotificationsPermissions () {
  console.log('Requesting notifications permission...')
  const permission = await Notification.requestPermission()

  if (permission === 'granted') {
    console.log('Notification permission granted.')
    await saveMessagingDeviceToken()
  } else {
    console.log('Unable to get permission to notify.')
  }
}

</script>
