<template>
  <div class="flex items-center justify-center lg:min-h-screen bg-gray-100">
    <div class="px-8 py-6 mt-4 text-left bg-white shadow-lg">
      <div class="flex justify-center">
        <router-link to="/">
          <img
            src="@/assets/dtl.png"
            alt=""
            class="w-20 h-20 text-blue-600"
          >
        </router-link>
      </div>
      <h3 class="text-2xl font-bold text-center">
        Set new password
      </h3>
      <!-- <span v-if="error" class="text-xs tracking-wide text-red-600"
        >{{ error }}
      </span> -->
      <form @submit.prevent="setPassword">
        <div class="mt-4">
          <div class="mt-4">
            <label class="block">New Password</label>
            <input
              v-model="state.password"
              type="password"
              placeholder="Password"
              class="
                w-full
                px-4
                py-2
                mt-2
                border
                rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600
              "
            >
            <span
              v-if="v$.password.$error"
              class="text-xs tracking-wide text-red-600"
            >{{ v$.password.$errors[0].$message }}
            </span>
          </div>
          <div class="mt-4">
            <label class="block">Confirm Password</label>
            <input
              v-model="state.confirm"
              type="password"
              placeholder="Re type your password"
              class="
                w-full
                px-4
                py-2
                mt-2
                border
                rounded-md
                focus:outline-none focus:ring-1 focus:ring-blue-600
              "
            >
            <span
              v-if="v$.confirm.$error"
              class="text-xs text-red-600"
            >{{ v$.confirm.$errors[0].$message }}
            </span>
          </div>
          <div class="flex items-baseline justify-between">
            <button
              class="
                px-6
                py-2
                mt-4
                text-white
                bg-blue-800
                rounded-lg
                hover:bg-blue-900
                w-full
              "
            >
              Set Password
            </button>
            <!-- <a href="#" class="text-sm text-blue-600 hover:underline">Forgot password?</a> -->
          </div>
        </div>
      </form>

      <div class="flex items-baseline justify-center">
        <p class="px-6 py-2 mt-4">
          <router-link
            to="/"
            class="px-6 py-2 text-sm text-blue-600 hover:underline"
          >
            Go to Home
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import useValidate from '@vuelidate/core'
import { required, sameAs } from '@vuelidate/validators'
import { reactive, computed } from 'vue'
export default {
  //   name: "Set Password",
  setup () {
    const state = reactive({
      password: null,
      confirm: null
    })
    const rules = computed(() => {
      return {
        password: {
          required
        },
        confirm: {
          required,
          sameAs: sameAs(state.password)
        }
      }
    })
    const v$ = useValidate(rules, state)
    return { state, v$ }
  },
  data () {
    return {
      error: null
    }
  },
  mounted () {
    // console.log(this.$store.state.registration.newuser);
    // console.log(this.v$.email.$errors[0].$message);
    document.title = this.$route.name
  },
  methods: {
    async setPassword () {
      this.v$.$validate() // checks all inputs
      if (!this.v$.$error) {
        this.error = null
        try {
          await this.$store.dispatch('SetPassword', {
            id: this.$store.state.newuser[0].id,
            password: this.state.password
          })
          if (this.$store.getters.authenticated) {
            await this.$router.push({ name: 'Home' })
          }
        } catch (error) {
          this.error = error
        } finally {
          // this.loading = false;
        }
      } else {
        this.error = 'Please enter correct data'
      }
    }
  }
}
</script>
